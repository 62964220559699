import { Fragment, useMemo } from 'react';
import { RiDraftLine } from 'react-icons/ri';

import StatusCircle from '../../components/StatusCircle';
import { formatMonthDayYear, formatMonthDayYearWithHours } from '../../utils/date';
import { reportStatus } from '../../utils/helpers';

const useColumns = (companyType, handleRowClick, handleReportClick, t, language) => {
  const columns = [
    {
      name: 'index',
      header: '',
      defaultWidth: 50,
      sortable: false,
      render: ({ remoteRowIndex }) => remoteRowIndex + 1
    },
    {
      name: 'record_dt',
      header: t('recordDate'),
      render: ({ value, data }) => (
        <a
          onClick={(e) => handleRowClick(e, data.id)}
          href="#"
          className="underline text-indigo-500"
        >
          {formatMonthDayYearWithHours(value, language)}
        </a>
      )
    },
    { name: 'record_by_name', header: t('recordedBy'), minWidth: 20 },
    {
      id: 'deviceType',
      name: 'endoscope',
      header: t('deviceType'),
      defaultFlex: 1,
      minWidth: 50,
      render: ({ value }) => value.device_type
    },
    {
      name: 'endoscope',
      header: t('endoscope'),
      defaultFlex: 1,
      minWidth: 50,
      sortable: false,
      render: ({ value }) => (
        <div className="flex flex-col">
          <span>{value.producer}</span>
          <span> {value.model}</span>
        </div>
      )
    },
    {
      id: 'serial',
      name: 'endoscope',
      header: t('serial'),
      defaultFlex: 1,
      minWidth: 50,
      render: ({ value }) => value.serial,
      sort: (a, b) => a?.serial?.localeCompare(b?.serial, 'en', { numeric: true })
    },

    {
      name: 'report',
      header: t('reports'),
      defaultFlex: 2,
      minWidth: 50,
      // cellProps: { style: { 'overflow-x': 'auto' } },
      render: ({ value }) => (
        <>
          {value.map((c) => (
            <Fragment key={c.id}>
              {c.draft ? (
                <div className="flex items-center mb-1" key={c.id}>
                  <RiDraftLine className="mr-1" />
                  <span>{` - ${c.generated_by_name}`}</span>
                </div>
              ) : (
                <div
                  key={c.id}
                  onClick={() => handleReportClick(c.id)}
                  className="flex items-center mb-1 underline text-indigo-500 cursor-pointer"
                >
                  <StatusCircle customStyle="mr-1" status={c.status} />
                  <span>{`(${reportStatus(c.status)}) - ${c.generated_by_name}`}</span>
                </div>
              )}
            </Fragment>
          ))}
        </>
      )
    },
    {
      name: 'reportDate',
      header: t('reportDate'),
      minWidth: 50,
      render: ({ data: { report } }) => (
        <>
          {report.map((c, i) => (
            <div key={c.id} className="mb-1">
              {formatMonthDayYear(c.generated_dt, language)}
            </div>
          ))}
        </>
      )
    }
  ];

  if (companyType === 1) {
    columns.push({
      name: 'customer',
      header: t('customer'),
      minWidth: 80,
      render: ({ data }) => data.endoscope.owner_name
    });
  }

  const formedColumns = useMemo(() => columns, [companyType, t]);

  return formedColumns;
};

export default useColumns;
