import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { getUserData } from './api/users';
import { getUser } from './utils/session';
import { filterUrl } from './utils/helpers';
import { urlAtom, appUserAtom } from './atoms/app';
import { videoTypeAtom } from './atoms/video';

function ProtectedRoutes() {
  const user = getUser();
  const [appUser, setAppUser] = useAtom(appUserAtom);
  const navigate = useNavigate();
  const [videoType, setVideoType] = useAtom(videoTypeAtom);
  const [pdfLink, setPdfLink] = useAtom(urlAtom);
  const location = useLocation();

  const loadAppUser = async () => {
    const res = (await getUserData()) || {};

    setAppUser({
      ...res,
      user_name: res.full_name,
      user_email: res.email,
      user_parent_company: res.parent_company,
      user_role: res.role
    });
  };

  useEffect(() => {
    const pathHasWt = location.pathname.includes('/wt-predictions/');
    const pathHasWo = location.pathname.includes('/wo-predictions/');

    if (pathHasWo) {
      const url = filterUrl(location.pathname, 'wo-predictions');
      setVideoType('wo');
      return user.access_token ? navigate(url, { replace: true }) : setPdfLink(url);
    }

    if (pathHasWt) {
      const url = filterUrl(location.pathname, 'wt-predictions');
      return user.access_token ? navigate(url, { replace: true }) : setPdfLink(url);
    }

    if (user.access_token && !appUser.user_id) {
      loadAppUser();
    }
  }, []);

  return user?.access_token ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedRoutes;
