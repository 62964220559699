import { Routes, Route } from 'react-router-dom';

import Login from './views/Login';
import SignUp from './views/SignUp/SignUp';
import ContactUs from './views/ContactUs/ContactUs';
import Documentation from './views/Documentation/Documentation';
import PdfDoc from './views/Documentation/PdfDoc';
import VideoList from './views/VideoList/VideoList';
import Company from './views/Company/Company';
import CreateUser from './views/CreateUser';
import ProtectedRoutes from './ProtectedRoutes';
import Video from './views/Video/Video';
import UserList from './views/UserList';
import NotFound from './views/NotFound';
import VideoUpload from './views/VideoUpload/VideoUpload';
import ChangePassword from './views/ChangePassword';
import ResetPassword from './views/ResetPassword';
import ChangeResetPassword from './views/ChangeResetPassword';

import './index.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/request-password" element={<ResetPassword />} />
      <Route path="/reset_password/:password" element={<ChangeResetPassword />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/company/:company_id" element={<Company />} />
        <Route path="/videos/:company_id" element={<VideoList />} />
        <Route path="/video/:video_id" element={<Video />} />
        <Route path="/create-user" element={<CreateUser />} />
        <Route path="/user-list" element={<UserList />} />
        <Route path="/video-upload" element={<VideoUpload />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/documentation" element={<Documentation />}>
          <Route path=":app/:type" element={<PdfDoc />} />
        </Route>
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/video/wo-predictions/:video_id" element={<Video />} />
        <Route path="/video/wt-predictions/:video_id" element={<Video />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
