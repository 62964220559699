import { atom } from 'jotai';
import { loadable } from 'jotai/utils';

import { currentCompanyAtom } from './companies';
import { tableFiltersAtom } from './filters';
import { getVideoList } from '../api/video';

export const videoTypeAtom = atom('wt');

export const isVideoLoadingAtom = atom(false);

export const framesAtom = atom({});

export const frameAtom = atom(0);

export const mediaTimeAtom = atom(0);

export const currentTimeAtom = atom(0);

export const targetFrameAtom = atom();

export const uploadIdAtom = atom('');

export const reportFramesAtom = atom({ id: null, frames: {} });

export const reportModeAtom = atom(false);
export const isReportingAtom = atom(false);
export const durationAtom = atom(0);
export const isPausedAtom = atom(true);

export const loadVideosAtom = atom(async (get) => {
  const currentCompany = get(currentCompanyAtom);
  const tableFilters = get(tableFiltersAtom);

  if (!currentCompany || tableFilters.currentCompany != currentCompany) {
    return { videos: [] };
  }

  const videos = await getVideoList(currentCompany, tableFilters);

  return videos;
});

export const loadableVideosAtom = loadable(loadVideosAtom);
