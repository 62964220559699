import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useAtom } from 'jotai';
import { useNavigate, Link } from 'react-router-dom';
import { Input, Button, Alert } from '@material-tailwind/react';
import PropTypes from 'prop-types';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { setUser } from '../utils/session';
import ErrorIcon from '../components/ErrorIcon';
import { atomWithToggle } from '../components/AtomWithToggle';
import { currentCompanyAtom } from '../atoms/companies';
import { appUserAtom } from '../atoms/app';
import { urlAtom } from '../atoms/app';
import { API_URL } from '../helpers/constants';

let errorMessageAtom = atom();

const hasErrorsAtom = atomWithToggle(false);
const userAtom = atom();
const passwordAtom = atom();

async function loginUser(credentials) {
  return fetch(API_URL + '/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  }).then((response) => {
    if (!response.ok) {
      return response
        .json()
        .catch(() => {
          throw new Error(response.status);
        })
        .then(({ message }) => {
          throw new Error(message);
        });
    }
    return response.json();
  });
}

export default function Login({}) {
  const { t } = useTranslation(['common', 'buttons']);
  const navigate = useNavigate();
  const [appUser, setAppUser] = useAtom(appUserAtom);
  const [showPassword, setShowPassword] = useState(false);
  const [pdfLink, setPdfLink] = useAtom(urlAtom);
  const [hasErrors, toggle] = useAtom(hasErrorsAtom, false);
  const [username, setUsername] = useAtom(userAtom, []);
  const [password, setPassword] = useAtom(passwordAtom, []);
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom);
  const [selectedCompany, setSelectedCompany] = useAtom(currentCompanyAtom);

  useEffect(() => {
    toggle(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response;
    try {
      response = await loginUser({
        username,
        password
      });
      if (response.detail == null && response.access_token != null) {
        setUser(response);
        setAppUser(response);
        setSelectedCompany(response.user_parent_company);
        toggle(false);

        if (pdfLink) {
          console.log('url', pdfLink);
          return navigate(pdfLink, { replace: true });
        }

        let userStartUrl = `/videos/${response.user_parent_company}`;
        navigate(userStartUrl, { replace: true });
      }
    } catch (error) {
      if (!username || !password) {
        setErrorMessage("Username and password can't be empty");
      } else {
        setErrorMessage('Username or password is incorrect');
      }
      toggle(true);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <section className="h-screen">
      <div className="container h-full px-6 py-24">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
          <div className="flex justify-center mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
            <img src="/logo2.png" className="w-[80%]" alt="Logo" />
          </div>
          <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
            <form onSubmit={handleSubmit}>
              <div className="flex w-full flex-col mb-4">
                {hasErrors ? (
                  <Alert icon={<ErrorIcon />} color="red">
                    {errorMessage}
                  </Alert>
                ) : null}
              </div>
              <div className="relative mb-6">
                <Input
                  color="blue"
                  label={t('common:username')}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <div className="relative mb-6">
                <Input
                  color="blue"
                  icon={
                    showPassword ? (
                      <FiEyeOff className="cursor-pointer" onClick={toggleShowPassword} />
                    ) : (
                      <FiEye className="cursor-pointer" onClick={toggleShowPassword} />
                    )
                  }
                  type={showPassword ? 'text' : 'password'}
                  label={t('common:password')}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="mb-6 flex items-center justify-between">
                <Link
                  to="/request-password"
                  className="underline text-blue-600 hover:text-blue-400"
                >
                  {t('buttons:forgotPassword')}
                </Link>
                <div>
                  <span className="mr-1 text-sm text-gray-600">{t('common:dontHaveAccount')}</span>
                  <Link to="/sign-up" className="underline text-blue-600 hover:text-blue-400">
                    {t('buttons:signUp')}
                  </Link>
                </div>
              </div>

              <div className="relative inline-block align-bottom w-full">
                <Button color="green" fullWidth type="submit">
                  {t('buttons:signIn')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

Login.propTypes = {};
