import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { TbPlayerPlayFilled, TbPlayerPauseFilled } from 'react-icons/tb';

import { isPausedAtom, isReportingAtom, durationAtom, currentTimeAtom } from '../../atoms/video';
import { formatTime, calculateProgress } from '../../utils/date';

import cls from 'classnames';

const ControlsToolbar = ({ videoRef }) => {
  const [isPaused, setIsPaused] = useAtom(isPausedAtom);
  const [isReporting, setIsReporting] = useAtom(isReportingAtom);
  const [duration, setDuration] = useAtom(durationAtom);
  const [currentTime, setCurrentTime] = useAtom(currentTimeAtom);
  const [isSeeking, setIsSeeking] = useState(false);

  const isPlaying =
    videoRef?.current?.currentTime > 0 &&
    !videoRef.current?.paused &&
    !videoRef?.current?.ended &&
    videoRef?.current?.readyState > videoRef?.current?.HAVE_CURRENT_DATA;

  const playFunction = () => {
    if (!isPlaying) {
      videoRef.current.play();
    }
  };

  const handleProgress = (e) => {
    const time = e.target.value;
    setCurrentTime(time);
    if (videoRef?.current) {
      videoRef.current.currentTime = time;
    }
  };

  const pauseFunction = () => {
    if (!videoRef?.current?.paused && isPlaying) {
      videoRef.current.pause();
    }
  };

  const handleKeydown = (e) => {
    e.preventDefault();
  };

  const handleSeekStart = () => {
    if (isPlaying) {
      setIsSeeking(true);
      pauseFunction();
    }
  };

  const handleSeekEnd = () => {
    if (isSeeking) {
      setIsSeeking(false);

      playFunction();
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateTime = () => setCurrentTime(video.currentTime);
    const updateDuration = () => setDuration(video.duration);

    video.addEventListener('timeupdate', updateTime);
    video.addEventListener('loadedmetadata', updateDuration);

    return () => {
      video.removeEventListener('timeupdate', updateTime);
      video.removeEventListener('loadedmetadata', updateDuration);
    };
  }, []);

  return (
    <div
      className={cls(
        `absolute bottom-0 w-full p-4 pt-3 bg-black bg-opacity-15 
        flex-col
      space-y-3 text-white
       hidden group-hover:flex`,
        {
          '[&&]:flex': isPaused && !isReporting,
          '[&&&&]:hidden': isReporting
        }
      )}
    >
      <div className="flex space-x-2">
        <>
          {isPaused ? (
            <TbPlayerPlayFilled className="cursor-pointer text-white" onClick={playFunction} />
          ) : (
            <TbPlayerPauseFilled className="cursor-pointer text-white" onClick={pauseFunction} />
          )}
        </>

        {/* Time Display */}
        <span className="text-xs font-mono">
          {formatTime(videoRef?.current?.currentTime)} / {formatTime(duration)}
        </span>
      </div>

      {/* Progress Bar  */}
      <input
        step={0.01}
        type="range"
        min="0"
        max={duration}
        value={currentTime}
        onChange={handleProgress}
        onKeyDown={handleKeydown}
        onMouseDown={handleSeekStart}
        onMouseUp={handleSeekEnd}
        onTouchStart={handleSeekStart}
        onTouchEnd={handleSeekEnd}
        style={{
          background: `linear-gradient(to right, #fff 0%, #fff ${calculateProgress(
            currentTime,
            duration
          )}%, #d0d3d9 ${calculateProgress(currentTime, duration)}%, #d0d3d9 100%)`
        }}
        className="flex-grow h-1 bg-gray-300 rounded-lg appearance-none cursor-pointer
        [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-white [&::-moz-range-thumb]:w-3 [&::-moz-range-thumb]:h-3 [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:bg-white
        "
      />
    </div>
  );
};

export default ControlsToolbar;
