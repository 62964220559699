import { IconButton } from '@material-tailwind/react';
import { RxCross2 } from 'react-icons/rx';

const CloseButton = ({ handleClose }) => {
  return (
    <IconButton ripple={false} color="blue-gray" size="sm" variant="text" onClick={handleClose}>
      <RxCross2 className="w-4 h-4" />
    </IconButton>
  );
};

export default CloseButton;
