import { useEffect, useState } from 'react';
import { atom, useAtom } from 'jotai';
import { Input, Button, Alert } from '@material-tailwind/react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import Header from '../components/Header';
import { getUser } from '../utils/session';
import { atomWithToggle } from '../components/AtomWithToggle';
import ErrorIcon from '../components/ErrorIcon';
import SuccessIcon from '../components/SuccessIcon';
import { API_URL } from '../helpers/constants';

const oldPasswordAtom = atom();
const passwordAtom = atom();
const repeatPasswordAtom = atom();
let errorMessageAtom = atom();
let successMessageAtom = atom();
const hasErrorsAtom = atomWithToggle(false);
const hasSuccessAtom = atomWithToggle(false);

function ChangePassword() {
  async function changePassword(oldPassword, newPassword) {
    return fetch(
      `${API_URL}/change_password?old_password=${encodeURIComponent(
        oldPassword
      )}&new_password=${encodeURIComponent(newPassword)}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.access_token}`
        }
      }
    ).then((response) => {
      if (!response.ok) {
        return response.json().catch(() => {
          throw new Error(response.status);
        });
      }
      return response.json();
    });
  }
  const { t } = useTranslation(['common', 'buttons']);
  const user = getUser();
  const [oldPassword, setOldPassword] = useAtom(oldPasswordAtom, []);
  const [password, setPassword] = useAtom(passwordAtom, []);
  const [repeatPassword, setRepeatPassword] = useAtom(repeatPasswordAtom, []);
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom);
  const [successMessage, setSuccessMessage] = useAtom(successMessageAtom);
  const [hasErrors, toggleError] = useAtom(hasErrorsAtom, false);
  const [hasSuccess, toggleSuccess] = useAtom(hasSuccessAtom, false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (password != repeatPassword) {
      setErrorMessage('Passwords do not match');
      toggleSuccess(false);
      toggleError(true);
      return;
    }
    let response;
    try {
      response = await changePassword(oldPassword, password);
      if (response.detail !== undefined) {
        setErrorMessage(response.detail);
        toggleError(true);
      } else {
        toggleError(false);
        toggleSuccess(true);
        setSuccessMessage('Your password has been changed');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    toggleError(false);
    toggleSuccess(false);
  }, []);

  return (
    <>
      <Header></Header>
      <div className="container h-full px-6 py-12">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
          <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
            <div className="flex w-full flex-col mb-4">
              {hasErrors ? (
                <Alert color="red" icon={<ErrorIcon />}>
                  {errorMessage}
                </Alert>
              ) : null}
              {hasSuccess ? (
                <Alert color="green" icon={<SuccessIcon />}>
                  {successMessage}{' '}
                </Alert>
              ) : null}
            </div>
            <form onSubmit={handleChangePassword}>
              <div className="relative mb-6">
                <Input
                  required
                  id="old-password"
                  color="blue"
                  icon={
                    showPassword ? (
                      <FiEyeOff className="cursor-pointer" onClick={toggleShowPassword} />
                    ) : (
                      <FiEye className="cursor-pointer" onClick={toggleShowPassword} />
                    )
                  }
                  type={showPassword ? 'text' : 'password'}
                  label={t('common:oldPassword')}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  id="password"
                  color="blue"
                  icon={
                    showPassword ? (
                      <FiEyeOff className="cursor-pointer" onClick={toggleShowPassword} />
                    ) : (
                      <FiEye className="cursor-pointer" onClick={toggleShowPassword} />
                    )
                  }
                  type={showPassword ? 'text' : 'password'}
                  label={t('common:newPassword')}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  id="repeat-password"
                  icon={
                    showPassword ? (
                      <FiEyeOff className="cursor-pointer" onClick={toggleShowPassword} />
                    ) : (
                      <FiEye className="cursor-pointer" onClick={toggleShowPassword} />
                    )
                  }
                  type={showPassword ? 'text' : 'password'}
                  color="blue"
                  label={t('common:repeatNewPassword')}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
              </div>
              <div className="relative mb-6">
                <Button type="submit" color="green">
                  {t('buttons:changePassword')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
