import { API_URL } from '../helpers/constants';
import { getUser } from '../utils/session';

export async function shareVideo(data, videoId) {
  const user = getUser();
  try {
    const res = await fetch(`${API_URL}/share/${videoId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(data)
    });
    const resJson = await res.json();
    return resJson;
  } catch (error) {
    console.log('Error', error);
  }
}

export async function contactus(data) {
  const user = getUser();
  try {
    const res = await fetch(`${API_URL}/contactus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(data)
    });
    const resJson = await res.json();
    return resJson;
  } catch (error) {
    console.log('Error', error);
    throw new Error(error);
  }
}
