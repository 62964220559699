import cls from 'classnames';

const FormError = ({ text = 'fieldRequired', isVisible }) => {
  return (
    <span
      className={cls('text-red-600 text-sm w-full invisible', {
        '[&&]:visible': isVisible
      })}
    >
      {text}
    </span>
  );
};

export default FormError;
