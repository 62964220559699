import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  framesAtom,
  reportFramesAtom,
  reportModeAtom,
  isReportingAtom,
  targetFrameAtom,
  mediaTimeAtom,
  currentTimeAtom,
  durationAtom,
  isVideoLoadingAtom
} from '../../atoms/video';
import { endoscopesAtom } from '../../atoms/endoscopes';
import { loadingReportsAtom } from '../../atoms/reports.js';
import { domainMax, objectContaminations, defaultObj } from '../../helpers/constants';
import Graph from '../Graph/Graph.js';
import { formContaminationData, convertRemToPixels } from '../../utils/helpers.js';
import GraphLegend from '../Graph/GraphLegend.js';

import Loader from '../Loader.js';

const ContaminationMap = ({ width }) => {
  const { t } = useTranslation('common');
  const [frames, setFrames] = useAtom(framesAtom);
  const [mediaTime, setMediaTime] = useAtom(mediaTimeAtom);
  const [currentTime, setCurrentTime] = useAtom(currentTimeAtom);
  const [reportFrames] = useAtom(reportFramesAtom);
  const [isVideoLoading, setIsVideoLoading] = useAtom(isVideoLoadingAtom);
  const [isReportsLoading, setIsReportsLoading] = useAtom(loadingReportsAtom);
  const [isReporting, setIsReporting] = useAtom(isReportingAtom);
  const [reportMode, setReportMode] = useAtom(reportModeAtom);
  const [duration, setDuration] = useAtom(durationAtom);
  const [targetFrame, setTargetFrame] = useAtom(targetFrameAtom);
  const [data] = useAtom(endoscopesAtom);
  const { fps, record_mode = '', record_speed = '' } = data;

  const currentFrames = reportMode ? frames : reportFrames?.frames;

  const chartData = useMemo(
    () => formContaminationData(currentFrames, fps, domainMax, record_mode, +record_speed),
    [currentFrames, fps, record_mode, record_speed]
  );
  const numberOfGraphs =
    duration && record_mode === 'Auto'
      ? Math.ceil((duration * +record_speed) / (domainMax * 10))
      : Math.ceil(duration / domainMax);

  const video = document.querySelector('video');
  const timeIndicator = record_mode === 'Auto' ? (mediaTime * +record_speed) / 10 : mediaTime;
  const currentTimeIndicator =
    record_mode === 'Auto' ? (currentTime * +record_speed) / 10 : currentTime;
  const widthMinusPadding = width - convertRemToPixels(6);

  const cleanBoxesWithoutLabels = (frame) => {
    return setFrames((prevFrames) => ({
      ...prevFrames,
      [frame]: frames[frame]?.filter((item) => item.label)
    }));
  };

  const handleTimeUpdate = (position) => {
    if (video) {
      const time = record_mode === 'Auto' ? (position * 10) / +record_speed : position;
      video.currentTime = time;
    }
  };

  const handleContaminationClick = (frame) => {
    if (video) {
      if (isReporting && frames[frame]) {
        cleanBoxesWithoutLabels(frame);
      }
      if (!video.paused) {
        video.pause();
      }
      setTargetFrame(+frame);
      video.currentTime = +frame / fps;
    }
  };

  return (
    <>
      <fieldset className="mt-4 mb-4 p-6 border-2 rounded-4xl border-neutral-100 border-opacity-100">
        <legend className="text-blue-main font-semibold	px-1">{t('common:contaminationMap')}</legend>
        <GraphLegend data={objectContaminations} />
        {(isReportsLoading || (duration === 0 && isVideoLoading)) && <Loader />}
        {!isReportsLoading && (
          <>
            {numberOfGraphs > 0 &&
              new Array(numberOfGraphs)
                .fill(1)
                .map((_, i) => (
                  <Graph
                    handleTimeUpdate={handleTimeUpdate}
                    currentTimeIndicator={currentTimeIndicator}
                    reportMode={reportMode}
                    timeIndicator={timeIndicator}
                    width={widthMinusPadding}
                    handleContaminationClick={handleContaminationClick}
                    isLast={numberOfGraphs === i + 1}
                    record_mode={record_mode}
                    key={i}
                    type={i + 1}
                    data={chartData[i] || defaultObj}
                  />
                ))}
          </>
        )}
      </fieldset>
    </>
  );
};

export default ContaminationMap;
