import { useState } from 'react';
import cls from 'classnames';

import { objectContaminations } from '../helpers/constants';

const ContaminationList = ({
  onSelect,
  style,
  label,
  labelToShow,
  isReflectedHorizontal,
  isReflectVertical
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (e, option) => {
    const selectedObj = objectContaminations.find((obj) => obj.label === option);
    setSelectedOption(selectedObj);
    onSelect(selectedObj);
  };

  if (selectedOption || label) {
    return (
      <div
        className={cls('absolute flex items-start pointer-events-none w-[120px] h-[250px]', {
          'justify-end': isReflectedHorizontal,
          'items-end': isReflectVertical
        })}
        style={style}
      >
        <div className={cls('contamination__container w-auto pointer-events-none ')}>
          {selectedOption?.labelToShow || labelToShow}
        </div>
      </div>
    );
  }

  return (
    <ul className="contamination__container absolute z-10 w-[120px]" style={style}>
      {objectContaminations.map((contamination) => (
        <li
          key={contamination.label}
          className="contamination__item"
          onClick={(e) => handleSelect(e, contamination.label)}
        >
          {contamination.labelToShow}
        </li>
      ))}
    </ul>
  );
};

export default ContaminationList;
