import { FlagImage } from 'react-international-phone';
import { components } from 'react-select';

const CustomValue = ({ children, ...props }) => {
  const [values, input] = children;
  const { getValue, hasValue } = props;

  const value = getValue();

  return (
    <components.ValueContainer {...props}>
      <div className="flex">
        {input}
        <FlagImage ref={props.innerRef} iso2={value[0].value} className="h-6 w-6" />
      </div>
    </components.ValueContainer>
  );
};

export default CustomValue;
