import { atom } from 'jotai';
import { loadable } from 'jotai/utils';

import { currentCompanyAtom } from './companies';
import { getSubscriptions } from '../api/subscriptions';

export const refreshSubscriptionsAtom = atom(0);

export const loadSubscriptions = atom(async (get) => {
  const currentCompany = get(currentCompanyAtom);
  const refreshSub = get(refreshSubscriptionsAtom);
  if (!currentCompany) {
    return {};
  }

  const subscriptions = await getSubscriptions(currentCompany);

  return subscriptions;
});

export const loadableSubscriptions = loadable(loadSubscriptions);
