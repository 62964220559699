import cls from 'classnames';
import { Spinner } from '@material-tailwind/react';

const Loader = ({ height = '200px', type = 'default', blur = false }) => (
  <div
    className={cls(`h-[${height}] flex items-center justify-center `, {
      'absolute w-full h-full top-0 bottom-0 left-0 right-0 z-50': type === 'absolute',
      'opacity-[.6] backdrop-blur-[3px] bg-white/70': blur
    })}
  >
    <div>
      <Spinner
        className={cls(`h-10 w-10 `, {
          'h-14 w-14': type === 'absolute'
        })}
      />
    </div>
  </div>
);

export default Loader;
