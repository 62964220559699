import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';

import { loadableInfoAtom } from '../../atoms/company';
import Loader from '../../components/Loader';
import { formatMonthDayYearWithHours } from '../../utils/date';

const CompanyInfo = () => {
  const { t, i18n } = useTranslation(['common']);
  const [info] = useAtom(loadableInfoAtom);
  const { company_name, created_dt, status, type } = info?.data || {};

  return (
    <>
      <h3 className="text-blue-main font-semibold my-6">{t('common:companyInfo')}:</h3>
      <div className="min-h-[200px] px-4">
        {info.state === 'loading' && <Loader height={0} />}
        {info.state === 'hasData' && (
          <div className="flex justify-between">
            <div className="mr-2">
              <p className="text-sm text-gray-600">{`${t('common:companyName')}:`}</p>
              <p className="text-lg mb-2">{company_name}</p>
            </div>
            <div className="mr-2">
              <p className="text-sm text-gray-600">{`${t('common:createdOn')}:`}</p>
              <p className="text-lg mb-2">
                {formatMonthDayYearWithHours(created_dt, i18n.language)}
              </p>
            </div>
            <div className="mr-2">
              <p className="text-sm text-gray-600">{`${t('common:companyType')}:`}</p>
              <p className="text-lg mb-2">{type}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">{`${t('common:currentState')}:`}</p>
              <p
                className={cls('text-lg mb-2 text-red-400', {
                  '[&&]:text-green-500': status === 'Active Subscription'
                })}
              >
                {status}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CompanyInfo;
