import React, { useState } from 'react';
import { Collapse, Menu, MenuHandler, MenuList } from '@material-tailwind/react';
import { IoChevronDownOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import MenuItems from './MenuItems';

const NavListMenu = () => {
  const { t } = useTranslation(['menu']);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <>
      <Menu open={isMenuOpen} handler={setIsMenuOpen} offset={{ mainAxis: 8 }} placement="bottom">
        <MenuHandler>
          <div
            className="flex items-center gap-2 py-2 px-3 cursor-pointer font-medium text-blue-700 hover:text-blue-500"
            selected={isMenuOpen || isMobileMenuOpen}
            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
          >
            {t('menu:support')}
            <IoChevronDownOutline
              className={`hidden h-3 w-3 transition-transform lg:block ${
                isMenuOpen ? 'rotate-180' : ''
              }`}
            />
            <IoChevronDownOutline
              className={`block h-3 w-3 transition-transform lg:hidden ${
                isMobileMenuOpen ? 'rotate-180' : ''
              }`}
            />
          </div>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-md lg:block">
          <ul className="grid grid-cols-1 gap-y-2 outline-none outline-0">
            <MenuItems />
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>
          <MenuItems />
        </Collapse>
      </div>
    </>
  );
};

export default NavListMenu;
