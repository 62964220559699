import { atom } from 'jotai';
import { loadable } from 'jotai/utils';

import { currentCompanyAtom } from './companies';
import { getUsers } from '../api/users';

export const refrestUsersAtom = atom(0);

export const loadUsersAtom = atom(async (get) => {
  const currentCompany = get(currentCompanyAtom);
  const refrestUsers = get(refrestUsersAtom);

  if (!currentCompany) {
    return [];
  }

  const users = await getUsers(currentCompany);

  return users;
});

export const loadableUsersAtom = loadable(loadUsersAtom);
