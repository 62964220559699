import { Input } from '@material-tailwind/react';
import { usePhoneInput } from 'react-international-phone';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

import { countriesOptions } from '../../helpers/constants';
import { setSelectValue } from '../../utils/helpers';
import CustomOption from './CustomOption';
import CustomValue from './CustomValue';

const PhoneInput = ({ value, onChange, countries, ...rest }) => {
  const { t } = useTranslation(['common']);
  const phone = usePhoneInput({
    defaultCountry: 'us',
    value,
    countries: countries,

    onChange: (data) => {
      onChange(data.phone);
    }
  });

  return (
    <div className="flex">
      <ReactSelect
        name="flag"
        components={{ ValueContainer: CustomValue }}
        options={countriesOptions}
        isSearchable={false}
        formatOptionLabel={CustomOption}
        value={setSelectValue(phone.country.iso2, countriesOptions)}
        onChange={(value) => phone.setCountry(value.value)}
        className="mr-2 w-24"
        classNames={{
          singleValue: () => 'w-48',
          control: () =>
            '[&&]:rounded-lg [&&]:cursor-pointer font-medium [&&]:border-blue-gray-200 h-full',
          option: () => '[&&]:cursor-pointer',
          menu: () => '[&&]:w-max',
          indicatorContainer: () => '[&&]:py-0'
        }}
      />
      <Input
        variant="outlined"
        label={t('common:phoneNumber')}
        color="blue"
        placeholder={t('common:phoneNumber')}
        value={phone.inputValue}
        onChange={phone.handlePhoneValueChange}
        type="tel"
        required
        inputRef={phone.inputRef}
      />
    </div>
  );
};

export default PhoneInput;
