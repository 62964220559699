import Cookies from 'js-cookie';
import { parseJSON } from 'date-fns';
export const setUser = (userData = {}) => {
  const expirationTime = parseJSON(userData.token_expire);

  Cookies.set(
    'user',
    JSON.stringify({
      access_token: userData.access_token,
      user_id: userData.user_id,
      user_role: userData.user_role
    }),
    { expires: expirationTime }
  );
};
export const getUser = () => {
  const userString = Cookies.get('user');

  const userData = userString ? JSON.parse(userString) : {};
  return userData;
};

export function getUserRole() {
  const userParsed = getUser();
  if (userParsed == null) {
    return;
  }
  return userParsed.user_role;
}

export function isUserAdmin() {
  const userRole = getUserRole();
  if (userRole != 'system-admin') {
    return false;
  } else {
    return true;
  }
}
