import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NotFound() {
  const { t } = useTranslation(['common']);
  return (
    <>
      <div id="notfound">
        <div className="notfound">
          <Link to="/">
            <div className="justify-center">
              <span className="underline">{t('common:goToHomepage')}</span>
              <img src="/logo2.png" className="h-20" />
            </div>
            <div className="notfound-404">
              <h1>
                4<span>0</span>4
              </h1>
            </div>
            <h2>{t('common:pageNotFound')}</h2>
          </Link>
        </div>
      </div>
    </>
  );
}

export default NotFound;
