import { API_URL } from '../helpers/constants';
import { getUser } from '../utils/session';

export function generateReport(data, video_id) {
  const user = getUser();
  const sourse = 0;
  return fetch(`${API_URL}/reports/${video_id}/${sourse}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.access_token}`
    },
    body: JSON.stringify(data)
  }).then((response) => {
    if (!response.ok) {
      return response
        .json()
        .catch(() => {
          throw new Error(response.status);
        })
        .then(({ message }) => {
          throw new Error(message);
        });
    }
    return response.json();
  });
}

export function saveDraft(data, video_id) {
  const user = getUser();
  return fetch(`${API_URL}/draft_reports/${video_id}/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.access_token}`
    },
    body: JSON.stringify(data)
  }).then((response) => {
    if (!response.ok) {
      return response
        .json()
        .catch(() => {
          throw new Error(response.status);
        })
        .then(({ message }) => {
          throw new Error(message);
        });
    }
    return response.json();
  });
}
export function deleteDraft(report_id) {
  const user = getUser();
  return fetch(`${API_URL}/draft_reports/${report_id}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.access_token}`
    }
  }).then((response) => {
    if (!response.ok) {
      return response
        .json()
        .catch(() => {
          throw new Error(response.status);
        })
        .then(({ message }) => {
          throw new Error(message);
        });
    }
    return response.json();
  });
}

export function getReport(report_id) {
  const user = getUser();
  return fetch(`${API_URL}/report/${report_id}/frames`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.access_token}`
    }
  }).then((response) => {
    if (!response.ok) {
      return response
        .json()
        .catch(() => {
          throw new Error(response.status);
        })
        .then(({ message }) => {
          throw new Error(message);
        });
    }
    return response.json();
  });
}
export function downloadReport(report_id) {
  const user = getUser();
  fetch(`${API_URL}/report/${report_id}/download`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${user.access_token}`
    }
  })
    .then((result) => {
      return result.json();
    })

    .then((data) => {
      if (data.link) {
        const a = Object.assign(document.createElement('a'), {
          href: data.link,
          style: 'display:none',
          download: 'report.pdf',
          target: '_blank'
        });
        // a.href = data.link;
        // a.setAttribute('download', 'report.pdf');
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })

    .catch((err) => {
      console.log(err);
    });
}
