import { useMemo } from 'react';

import { formatMonthDayYear } from '../../utils/date';

const useColumns = (t, language) => {
  const columns = [
    {
      name: 'index',
      header: '',
      defaultWidth: 50,
      render: ({ remoteRowIndex }) => remoteRowIndex + 1
    },
    {
      name: 'subs_start',
      defaultFlex: 1,
      textAlign: 'center',
      header: t('from'),
      render: ({ data }) =>
        data.length > 0 ? formatMonthDayYear(data[1].subs_start, language) : '--'
    },
    {
      name: 'subs_end',
      defaultFlex: 1,
      textAlign: 'center',
      header: t('to'),
      render: ({ data }) =>
        data.length > 0 ? formatMonthDayYear(data[1].subs_end, language) : '--'
    },
    {
      name: 'special_info',
      header: t('hwId'),
      render: ({ data }) => (data.length > 0 ? data[1].special_info : '')
    }
  ];

  const formedColumns = useMemo(() => columns, [t]);

  return formedColumns;
};

export default useColumns;
