import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { modalAtom, appUserAtom } from '../../atoms/app';
import { loadableUsersAtom } from '../../atoms/users';
import { currentCompanyAtom } from '../../atoms/companies';
import Loader from '../../components/Loader';
import { isUserAdmin } from '../../utils/session';
import useColumns from './useColumns';

const UsersGrid = ({}) => {
  const { t } = useTranslation(['common']);
  const [users] = useAtom(loadableUsersAtom);
  const [modal, setModal] = useAtom(modalAtom);
  const [selectedCompany, setSelectedCompany] = useAtom(currentCompanyAtom);
  const isAdmin = isUserAdmin();
  const [appUser, setAppUser] = useAtom(appUserAtom);
  const { user_parent_company, user_role } = appUser || {};

  const handleOpen = (name, userId) => {
    return setModal({ name, isOpen: true, data: { userId } });
  };

  const columns = useColumns(t, user_role, handleOpen);
  const gridStyle = {
    minHeight: !isAdmin && user_parent_company === selectedCompany ? '55vh' : '70vh'
  };

  return (
    <>
      {users.state === 'loading' && <Loader height={0} />}
      {users.state === 'hasData' && (
        <ReactDataGrid
          idProperty="UsersGrid"
          columns={columns}
          rowHeight={60}
          rowMinHeight={60}
          rowClassName={'group/item'}
          sortable={false}
          style={gridStyle}
          dataSource={users?.data ? users?.data : []}
        />
      )}
    </>
  );
};

export default UsersGrid;
