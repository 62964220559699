import { useMemo } from 'react';
const useColumns = (t) => {
  const columns = useMemo(
    () => [
      {
        name: 'index',
        header: '',
        defaultWidth: 50,
        render: ({ remoteRowIndex }) => remoteRowIndex + 1
      },
      {
        name: 'full_name',
        defaultFlex: 1,
        textAlign: 'center',
        header: t('name'),
        render: ({ value }) => value || '--'
      },
      {
        name: 'email',
        defaultFlex: 1,
        textAlign: 'center',
        header: t('email'),
        render: ({ value }) => value || '--'
      }
    ],
    [t]
  );

  return columns;
};

export default useColumns;
