import { useAtom } from 'jotai';
import isEqual from 'lodash.isequal';

import ContaminationList from './ContaminationList';
import { objectContaminations } from '../helpers/constants';
import { optionByLabel } from '../utils/helpers';
import {
  contaminationListWidth,
  contaminationCloseWidth,
  contaminationListHeight
} from '../helpers/constants';
import { framesAtom } from '../atoms/video';
import Box from './Box';

const BoundedBoxes = ({ frame, offsetWidth, offsetHeight }) => {
  const [frames, setFrames] = useAtom(framesAtom);

  const deleteRectangle = (e, rectangle) => {
    const newRectangleList = frames[frame]?.filter((item) => !isEqual(item, rectangle));
    setFrames((prevRectangles) => {
      if (!newRectangleList.length) {
        delete prevRectangles[frame];
        return { ...prevRectangles };
      }

      return { ...prevRectangles, [frame]: [...newRectangleList] };
    });
  };

  const handleSelect = (selectedOption, rectangleIndex) => {
    setFrames((prevRectangles) => {
      const newFrame = prevRectangles[frame];
      newFrame[rectangleIndex] = { ...newFrame[rectangleIndex], ...selectedOption };
      return {
        ...prevRectangles,
        [frame]: [...newFrame]
      };
    });
  };

  return (
    <>
      {frames[frame]?.map((rectangle, i) => {
        const { label, x_min, y_min, x_max, y_max } = rectangle;
        const left = x_max - x_min < 0 ? x_max * offsetWidth : x_min * offsetWidth;
        const top = y_max - y_min < 0 ? y_max * offsetHeight : y_min * offsetHeight;
        const width = Math.abs(x_min - x_max) * offsetWidth;
        const height = Math.abs(y_min - y_max) * offsetHeight;
        const option = optionByLabel(label, objectContaminations);
        const borderColor = rectangle.color || option?.color || 'red';
        const labelToShow = rectangle.labelToShow || option?.labelToShow;

        const isReflectedHorizontal = offsetWidth - left - width < contaminationListWidth;
        const isReflectVertical = offsetHeight - top - height < contaminationListHeight;

        const positionLeft = isReflectedHorizontal ? left - contaminationListWidth : left + width;
        const positionTop = isReflectVertical
          ? top + height - contaminationListHeight
          : top + height;

        let closePositionLeft = isReflectedHorizontal
          ? left - contaminationCloseWidth
          : left + width;

        // when menu is open and reflected vertical
        if (isReflectVertical && !label) {
          closePositionLeft = isReflectedHorizontal
            ? left
            : closePositionLeft - contaminationCloseWidth;
        }

        return (
          <div key={`${frame}_${i}_${rectangle.x_min}_${rectangle.y_max}`}>
            <Box borderColor={borderColor} left={left} top={top} width={width} height={height} />
            <button
              className="close-rectangle__button absolute"
              onClick={(e) => deleteRectangle(e, rectangle)}
              style={{
                left: closePositionLeft,
                top: top
              }}
            ></button>
            <ContaminationList
              onSelect={(selectedOption) => handleSelect(selectedOption, i)}
              label={label}
              labelToShow={labelToShow}
              isReflectedHorizontal={isReflectedHorizontal}
              isReflectVertical={isReflectVertical}
              style={{
                left: positionLeft,
                top: positionTop
              }}
            />
          </div>
        );
      })}
    </>
  );
};
export default BoundedBoxes;
