import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MdOutlineEdit } from 'react-icons/md';

import { loadableUsersAtom } from '../../atoms/users';
import Loader from '../../components/Loader';
import { isUserAdmin } from '../../utils/session';
import { currentCompanyAtom } from '../../atoms/companies';
import { modalAtom, appUserAtom } from '../../atoms/app';

const CurrentUser = () => {
  const { t } = useTranslation(['common']);
  const isAdmin = isUserAdmin();
  const [modal, setModal] = useAtom(modalAtom);
  const [users] = useAtom(loadableUsersAtom);
  const [appUser, setAppUser] = useAtom(appUserAtom);
  const [selectedCompany] = useAtom(currentCompanyAtom);

  const { user_parent_company, user_id } = appUser || {};
  const { username, firstName, lastName, email, parent_company } =
    (users.state === 'hasData' && users?.data?.find((c) => c.user_id === user_id)) || {};

  const handleOpen = () => {
    return setModal({ name: 'editUser', isOpen: true, data: {} });
  };

  return (
    <>
      {!isAdmin && user_parent_company === selectedCompany && (
        <>
          <h3 className="text-blue-main font-semibold my-6">{t('common:currentUser')}:</h3>
          <div className="min-h-[100px] px-8">
            {users.state === 'loading' && <Loader height={0} />}
            {users.state === 'hasData' && (
              <div className="flex justify-between relative">
                <div className="mr-2">
                  <p className=" text-sm text-gray-600">{`${t('common:userName')}:`}</p>
                  <div
                    onClick={handleOpen}
                    className="flex items-center cursor-pointer group/item hover:text-gray-600"
                  >
                    <p className="text-lg mr-1">{username}</p>
                    <MdOutlineEdit className="h-4 w-4" />
                  </div>
                </div>
                <div className="mr-2">
                  <p className="text-sm text-gray-600">{`${t('common:firstName')}:`}</p>
                  <div
                    onClick={handleOpen}
                    className="flex items-center cursor-pointer group/item hover:text-gray-600"
                  >
                    <p className="text-lg mr-1">{firstName}</p>
                    <MdOutlineEdit className="h-4 w-4" />
                  </div>
                </div>
                <div className="mr-2">
                  <p className="text-sm text-gray-600">{`${t('common:lastName')}:`}</p>
                  <div
                    onClick={handleOpen}
                    className="flex items-center cursor-pointer group/item hover:text-gray-600"
                  >
                    <p className="text-lg mr-1">{lastName}</p>
                    <MdOutlineEdit className="h-4 w-4" />
                  </div>
                </div>
                <div className="mr-2">
                  <p className="text-sm text-gray-600">{`${t('common:email')}:`}</p>
                  <div
                    onClick={handleOpen}
                    className="flex items-center cursor-pointer group/item hover:text-gray-600"
                  >
                    <p className="text-lg mr-1">{email}</p>
                    <MdOutlineEdit className="h-4 w-4" />
                  </div>
                </div>
                <div className="mr-2">
                  <p className="text-sm text-gray-600">{`${t('common:parentCompany')}:`}</p>
                  <p className="text-lg mr-1">{parent_company}</p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CurrentUser;
