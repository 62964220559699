import * as d3 from 'd3';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  domainMax,
  marginBottom,
  xOffset,
  contaminationsQuantatity,
  imageGap
} from '../../helpers/constants';
import DraggableTimeIndicator from './DraggableTimeIndicator';

import { maxContaminationsInSec } from '../../utils/helpers';

const Graph = ({
  data,
  type,
  record_mode,
  isLast,
  handleContaminationClick,
  width,
  timeIndicator = 0,
  currentTimeIndicator,
  handleTimeUpdate
}) => {
  const { t } = useTranslation('common');
  const svgRef = useRef();

  const seconds = Object.keys(data);
  const max = useMemo(() => {
    return maxContaminationsInSec(data, seconds, contaminationsQuantatity);
  }, [data]);

  const imageSize = (width - xOffset * 2) / domainMax - imageGap;

  const height = imageSize * max + imageGap * max * 2 + marginBottom;
  const chartBottomY = height - marginBottom;
  const order = domainMax * type;
  const adjustPosition = order - domainMax;
  const xScale = d3
    .scaleLinear()
    .domain([adjustPosition, order])
    .range([xOffset, width - xOffset]);

  const xAxis = d3
    .axisBottom(xScale)
    .ticks(30)
    .tickFormat((x) => (x % 4 === 0 || x == 0 ? x : ''));

  // Create the vertical scale.
  const yScale = d3
    .scaleLinear()
    .domain([0, max])
    .range([height - marginBottom, 0]);

  useEffect(() => {
    d3.select(`.x-axis-${type}`).call(xAxis);
  }, [xAxis]);

  return (
    <>
      {width > 0 && (
        <>
          <svg
            ref={svgRef}
            className={`contimination-map_${type}`}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
          >
            <>
              {currentTimeIndicator >= adjustPosition && currentTimeIndicator <= order && (
                <DraggableTimeIndicator
                  height={height}
                  xScale={xScale}
                  timeIndicator={timeIndicator} // Initial position or state variable
                  callback={handleTimeUpdate}
                />
              )}

              {seconds.map((sec, i) =>
                Object.keys(data[sec]).map((damageType, position) => (
                  <g key={`${data[sec][damageType]}-${position}`}>
                    <image
                      className="cursor-pointer	"
                      onClick={() => {
                        handleContaminationClick(data[sec][damageType].frame);
                      }}
                      key={`${data[sec][damageType]}-${position}_image`}
                      href={`/${data[sec][damageType].label}.png`}
                      height={imageSize}
                      width={imageSize}
                      x={xScale(+sec)}
                      y={yScale(position + 1)}
                    />
                  </g>
                ))
              )}
            </>
            <g className={`x-axis-${type}`} transform={`translate(0,${chartBottomY})`}></g>
          </svg>
          {isLast && (
            <div className="text-center text-sm	font-medium">
              {record_mode === 'Auto' ? t('common:lengthEndoscope') : t('common:timeVideo')}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Graph;
