import { atom } from 'jotai';

export const sidePanelAtom = atom(false);
export const widthAtom = atom(0);
export const isLoadingAtom = atom(false);

export const modalAtom = atom({ name: '', isOpen: false });
export const notificationAtom = atom({ isOpen: false, message: '', type: '' });

export const urlAtom = atom(false);

export const appUserAtom = atom({});
