import { string, number, oneOfType, bool, func, node, object } from 'prop-types';
import { useDropzone } from 'react-dropzone';

const FileInput = ({ accept, children, disabled, noDrag, onDrop, maxSize, maxFiles, ...props }) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles,
    accept,
    noDrag,
    disabled,
    onDrop: (acceptedFiles) => {
      let files = null;

      if (!acceptedFiles.length) {
        return false;
      }

      if (maxSize) {
        files = acceptedFiles.filter((c) => !(c && c.size > maxSize));
      }

      onDrop(files);

      return false;
    }
  });

  return (
    <div {...getRootProps()} {...props}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

FileInput.defaultProps = {
  accept: {},
  noDrag: false,
  disabled: false,
  maxFiles: 0,
  maxSize: 20 * 1e6, // 20MB
  multiple: true,
  onDrop: () => {}
};

FileInput.propTypes = {
  /**
   * Set accepted file types.
   */
  accept: object,
  /**
   * The children of the component.
   */
  children: node.isRequired,
  /**
   * Enable/disable the file input.
   */
  disabled: bool,
  /**
   * If true, disables drag 'n' drop.
   */
  noDrag: bool,
  /**
   * The drop event occurs
   */
  onDrop: func,
  /**
   * Maximal size the file in the megabytes.
   */
  maxSize: oneOfType([string, number]),
  /**
   * 	Allow drag 'n' drop (or selection from the file dialog) of multiple files
   */
  multiple: bool,
  /**
   * Maximum accepted number of files.
   */
  maxFiles: number
};

export default FileInput;
