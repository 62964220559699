import { TiArrowBackOutline } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-tailwind/react';
import cls from 'classnames';

const BackButton = ({ customStyle = '', customHandler }) => {
  const navigate = useNavigate();
  const goBack = (e) => {
    customHandler ? customHandler(e) : navigate(-1);
  };
  return (
    <Button
      ripple={false}
      variant="gradient"
      color={'light-blue'}
      className={cls(`my-2 rounded-2xl ${customStyle}`)}
      onClick={goBack}
    >
      <TiArrowBackOutline className="h-4 w-4" />
    </Button>
  );
};

export default BackButton;
