import { useRef, useState } from 'react';

function Otp({ numberOfDigits, onChange, setValue }) {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(''));

  const otpBoxReference = useRef([]);

  function handleChange(value, index) {
    console.log('change');
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);
    onChange(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === 'Enter' && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
    // if (e.key === 'ArrowRight' && index < numberOfDigits - 1) {
    //   otpBoxReference.current[index + 1].focus();
    // }
    // if (e.key === 'ArrowLeft' && index > 0) {
    //   otpBoxReference.current[index - 1].focus();
    // }
  }

  const handleOneInputPaste = (event) => {
    event.preventDefault();
    const content = event.clipboardData.getData('text/plain');

    if (content?.length === numberOfDigits) {
      const newContent = content.split('');

      setOtp(newContent);
      return setValue('pincode', newContent, { shouldValidate: true });
    }
  };

  return (
    <div className="flex justify-between items-center gap-4">
      {otp.map((digit, index) => (
        <input
          onPaste={handleOneInputPaste}
          key={index}
          value={digit}
          maxLength={1}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
          ref={(reference) => (otpBoxReference.current[index] = reference)}
          className={`border w-20 h-auto p-3 rounded-md block  focus:border-2 focus:outline-none appearance-none`}
        />
      ))}
    </div>
  );
}

export default Otp;
