import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { capFirstLetterInEveryWord } from '../utils/strings';
import { formatMonthDayYearWithHours } from '../utils/date';
import { endoscopesAtom } from '../atoms/endoscopes';

const VideoDetails = ({}) => {
  const { t, i18n } = useTranslation(['common']);
  const [data] = useAtom(endoscopesAtom);

  const {
    endoscope,
    record_by = '',
    record_dt,
    record_mode = '',
    record_speed = '',
    led_brightness
  } = data;
  const { producer = '', model = '', serial, owner_name, endosc_type } = endoscope || {};

  return (
    <div className="flex w-full">
      {endoscope && (
        <>
          <fieldset className="w-[40%] flex flex-col p-6 border-2 rounded-4xl border-neutral-100 border-opacity-100 font-semibold">
            <legend className="text-blue-main font-semibold px-1">
              {t('common:endoscopeInfo')}
            </legend>
            <p className="text-sm text-gray-600">{`${t('common:oemAndModel')}:`}</p>
            <p className="text-lg mb-2">{`${producer} ${model}`}</p>
            <p className="text-sm text-gray-600">{`${t('common:serial')}:`}</p>
            <p className="text-lg mb-2">{serial}</p>
            <p className="text-sm text-gray-600">{`${t('common:ownedBy')}:`}</p>
            <p className="text-lg mb-2">{owner_name}</p>
          </fieldset>

          <fieldset className="flex flex-col w-[60%] ml-4 p-6 border-2 rounded-4xl	border-neutral-100 border-opacity-100">
            <legend className="text-blue-main font-semibold px-1">
              {t('common:inspectionDetails')}
            </legend>
            <div className="flex w-full justify-between font-semibold">
              <div>
                <p className="text-sm text-gray-600">{`${t('common:by')}:`}</p>
                <p className="text-lg mb-2">{capFirstLetterInEveryWord(record_by)}</p>
                <p className="text-sm text-gray-600">{`${t('common:date')}:`}</p>
                <p className="text-lg mb-2 font-semibold">
                  {formatMonthDayYearWithHours(record_dt, i18n.language)}
                </p>
                {endosc_type && endosc_type !== '0' && (
                  <>
                    <p className="text-sm text-gray-600">{`${t('common:tubeType')}:`}</p>
                    <p className="text-lg mb-2">{`${t(`common:${endosc_type}`)}`}</p>
                  </>
                )}
              </div>
              <div>
                <p className="text-sm text-gray-600">{`${t('common:recordMode')}:`}</p>
                <p className="text-lg mb-2">{record_mode}</p>
                {record_mode === 'Auto' && (
                  <>
                    <p className="text-sm text-gray-600">{`${t('common:recordSpeed')}:`}</p>
                    <p className="text-lg mb-2">{`${record_speed} mm/s`}</p>
                  </>
                )}
                <p className="text-sm text-gray-600">{`${t('common:intensity')}:`}</p>
                <p className="text-lg mb-2">{led_brightness}</p>
              </div>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
};

export default VideoDetails;
