import { format } from 'date-fns';
import { enUS, fr, de } from 'date-fns/locale';

export function getLocale(lang) {
  switch (lang) {
    case 'fr':
      return fr;
    case 'de':
      return de;
    default:
      return enUS;
  }
}

export function formatMonthDayYear(value, lang) {
  return value ? format(new Date(value), 'PP', { locale: getLocale(lang) }) : '';
}
export function formatMonthDayYearWithHours(value, lang) {
  return value ? format(new Date(value), 'PPp', { locale: getLocale(lang) }) : '';
}

export function formatYearMonthDayWithHours(value) {
  return value ? format(new Date(value), 'yyyy-MM-dd HH:mm') : '';
}

export const formatTime = (timeInSeconds) => {
  if (!timeInSeconds) {
    return '00:00';
  }
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const calculateProgress = (currentTime, duration) => {
  return duration ? (currentTime / duration) * 100 : 0;
};
