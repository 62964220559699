import { API_URL } from '../helpers/constants';
import { getUser } from '../utils/session';
import { generateUrl, formDevices, formEndoscopes, formOwners } from '../utils/helpers';

export async function getVideoList(selectedCompany, tableFilters) {
  try {
    const user = getUser();
    let url = API_URL + '/videos/' + selectedCompany;
    const queryParams = generateUrl(tableFilters);
    if (queryParams.length) {
      url += '?' + queryParams.join('&');
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });

    if (response.ok) {
      const json = await response.json();

      const devices = formDevices(json);
      const endoscopes = formEndoscopes(json);
      const owners = formOwners(json);

      return {
        videos: json,
        devices,
        endoscopes: endoscopes.endoscopes,
        deviceType: endoscopes.deviceType,
        owners
      };
    }
  } catch (e) {
    console.log('Error', e);
  }
}

export async function registerVideo(data) {
  const user = getUser();

  try {
    const response = await fetch(API_URL + '/register_video', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(data)
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.log(json);
      throw new Error(json.detail);
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}

export async function uploadVideo(video_id, data) {
  try {
    const user = getUser();

    const formData = new FormData();
    formData.append('file', data);
    const response = await fetch(API_URL + `/upload_video/${video_id}`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${user.access_token}`
        // 'Content-Type': 'multipart/form-data'
      },
      body: formData
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.log(json);
      throw new Error(json.detail);
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}
