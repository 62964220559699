import cls from 'classnames';
const Box = ({ left, top, width, height, borderColor, offsetHeight }) => {
  return (
    <div
      className={cls(
        `rectangle absolute pointer-events-none border-solid border-3 border-red-main `
      )}
      style={{ left, top, width, height, borderColor }}
    ></div>
  );
};

export default Box;
