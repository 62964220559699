import { components } from 'react-select';
import { TbWorld } from 'react-icons/tb';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <TbWorld />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
