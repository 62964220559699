import { useState } from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Button,
  Alert
} from '@material-tailwind/react';
import { Controller, useForm } from 'react-hook-form';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MdErrorOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { registerUserInExistingCompany } from '../../../api/auth';
import { modalAtom } from '../../../atoms/app';
import CloseButton from '../../CloseButton/CloseButton';
import Otp from '../../Otp/Otp';
import FormError from '../../../components/FormError/FormError';

const PinModal = ({ size }) => {
  const { t } = useTranslation(['common', 'buttons']);
  const navigate = useNavigate();
  const [modal, setModal] = useAtom(modalAtom);
  const [errorMessage, setErrorMessage] = useState('');
  const { name, isOpen, data } = modal;
  const email = data?.email || '';
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors }
  } = useForm({});

  const handleClose = () => {
    setModal({ name: '', isOpen: false, data: {} });
    setErrorMessage('');
  };

  const onSubmit = async ({ pincode }, e) => {
    try {
      if (errorMessage) {
        setErrorMessage('');
      }
      const res = await registerUserInExistingCompany({
        ...data.formData,
        pincode: pincode.join('')
      });
      setModal({ name: '', isOpen: false, data: {} });

      navigate('/', { replace: true });
    } catch (e) {
      console.log('submit error', e);
      setErrorMessage(e?.response?.data?.message || e?.message);
    }
  };

  return (
    <>
      <Dialog open={isOpen && name === 'pin'} size={size || 'sm'}>
        <DialogHeader className="justify-end">
          <CloseButton handleClose={handleClose} />
        </DialogHeader>
        <DialogBody>
          <p className="text-center">
            {t('common:wantToRegister')}
            <span className="ml-1 text-blue-main">{email}.</span>
          </p>
          <p className="mb-6 text-center">{t('common:checkEmail')}</p>
          <Typography className="mb-2" variant="h4">
            {t('common:enterPin')}:
          </Typography>
          <form noValidate>
            <div className="flex w-full flex-col mb-4">
              {errorMessage ? (
                <Alert className="flex items-center bg-red-500" icon={<MdErrorOutline />}>
                  {errorMessage}
                </Alert>
              ) : null}
            </div>
            <Controller
              name="pincode"
              control={control}
              render={({ field }) => (
                <Otp numberOfDigits={6} {...field} ref={null} setValue={setValue} />
              )}
              rules={{
                validate: (value) =>
                  (value?.length === 6 && value.every((c) => c)) || t('common:fieldRequired')
              }}
            />
          </form>

          <FormError text={errors?.pincode?.message} isVisible={errors?.pincode} />
        </DialogBody>

        <DialogFooter>
          <Button onClick={handleSubmit(onSubmit)} color="green">
            {t('buttons:signUp')}
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default PinModal;
