import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { FaPlus } from 'react-icons/fa6';
import { Button } from '@material-tailwind/react';

import Header from '../../components/Header';
import Notification from '../../components/Notification';
import BackButton from '../../components/BackButton/BackButton';
import CompanyInfo from '../../components/CompanyInfo/CompanyInfo';
import DevicesGrid from '../../components/DevicesGrid/DevicesGrid';
import SubscriptionsGrid from '../../components/SubscriptionsGrid/SubscriptionsGrid';
import RemoveModal from '../../components/modals/RemoveModal/RemoveModal';
import SubscriptionModal from '../../components/modals/SubscriptionModal/SubscriptionModal';
import EmailsGrid from '../../components/EmailsGrid/EmailsGrid';
import { isUserAdmin } from '../../utils/session';
import { modalAtom, isLoadingAtom, notificationAtom, appUserAtom } from '../../atoms/app';
import { companiesDataAtom, currentCompanyAtom } from '../../atoms/companies';
import { fetchCompanies, deleteCompany } from '../../api/companies';

const Company = () => {
  const { t } = useTranslation(['common', 'buttons']);
  const [appUser, setAppUser] = useAtom(appUserAtom);
  const [modal, setModal] = useAtom(modalAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [companiesData, setCompaniesData] = useAtom(companiesDataAtom);
  const [selectedCompany, setSelectedCompany] = useAtom(currentCompanyAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const isAdmin = isUserAdmin();

  const { user_parent_company } = appUser;

  const handleRemove = async () => {
    try {
      setIsLoading(true);
      await deleteCompany(selectedCompany);

      handleClose();
      setIsLoading(false);
      setNotification({ isOpen: true, type: 'success', message: t('common:companyRemoved') });
      fetchCompanies(setCompaniesData);
      setSelectedCompany(user_parent_company);
    } catch (e) {
      console.log('Error', e);
      setNotification({ isOpen: true, type: 'failure', message: t('common:somethingWrong') });
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      setModal({ name: '', isOpen: false, data: {} });
    }
  };

  return (
    <>
      <Header></Header>
      {<Notification isTempopary={true} />}

      <BackButton customStyle="ml-4" />
      <div className={cls('grid grid-cols-10 font-semibold')}>
        <div className={cls('col-span-6 px-4', {})}>
          <CompanyInfo />
          <h3 className="text-blue-main font-semibold my-6">{t('common:devices')}:</h3>
          <DevicesGrid />
        </div>
        <div className={cls('px-4 col-span-4')}>
          <div className="flex justify-between items-center">
            <h3 className="text-blue-main font-semibold my-6">{t('common:subscriptions')}:</h3>
            {isAdmin && (
              <Button
                ripple={false}
                className="flex items-center"
                variant="gradient"
                color="light-blue"
                onClick={() => setModal({ name: 'subscription', isOpen: true })}
              >
                <FaPlus className="mr-1 text-sm" />
                <span>{t('buttons:add')}</span>
              </Button>
            )}
          </div>
          <SubscriptionsGrid />
          <h3 className="text-blue-main font-semibold my-6">{t('common:emailsList')}:</h3>
          <EmailsGrid />
          {isAdmin && user_parent_company !== selectedCompany && (
            <div className="flex w-full my-8 justify-end">
              <Button
                ripple={false}
                onClick={() => setModal({ name: 'remove', isOpen: true })}
                className=""
                variant="gradient"
                color="red"
              >
                {t('buttons:removeCompany')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <RemoveModal handleClose={handleClose} handleRemove={handleRemove} />
      <SubscriptionModal size="" />
    </>
  );
};

export default Company;
