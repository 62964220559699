import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loadableSubscriptions } from '../../atoms/subscriptions';
import Loader from '../../components/Loader';
import useColumns from './useColumns';

const SubscriptionsGrid = ({}) => {
  const { t, i18n } = useTranslation(['common']);
  const [subscriptions] = useAtom(loadableSubscriptions);
  const columns = useColumns(t, i18n.language);

  return (
    <div className="min-h-[200px]">
      {subscriptions.state === 'loading' && <Loader height={0} />}
      {subscriptions.state === 'hasData' && (
        <ReactDataGrid
          idProperty="subscriptionsGrid"
          columns={columns}
          rowHeight={null}
          sortable={false}
          rowMinHeight={60}
          dataSource={subscriptions?.data ? Object.entries(subscriptions?.data) : []}
        />
      )}
    </div>
  );
};

export default SubscriptionsGrid;
