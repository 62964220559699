import { API_URL } from '../helpers/constants';
import { getUser } from '../utils/session';

export async function getInfo(currentCompany) {
  try {
    const user = getUser();

    const response = await fetch(`${API_URL}/company/${currentCompany}/info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });

    if (response.ok) {
      const json = await response.json();
      return json;
    }
  } catch (e) {
    console.log('Error', e);
  }
}

export async function getDevices(currentCompany) {
  try {
    const user = getUser();

    const response = await fetch(`${API_URL}/company/${currentCompany}/devices`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });

    if (response.ok) {
      const json = await response.json();
      return json;
    }
  } catch (e) {
    console.log('Error', e);
  }
}
export async function getEmails(currentCompany) {
  try {
    const user = getUser();

    const response = await fetch(`${API_URL}/company/${currentCompany}/email_list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });

    if (response.ok) {
      const json = await response.json();
      return json;
    }
  } catch (e) {
    console.log('Error', e);
  }
}
