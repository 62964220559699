import { API_URL } from '../helpers/constants';
import { getUser } from '../utils/session';

export const fetchDocumentation = async (app, type) => {
  try {
    const user = getUser();
    const res = await fetch(`${API_URL}/docs/${app}/${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });
    const resJson = await res.json();
    return resJson;
  } catch (error) {
    console.log('Error', error);
    throw new Error(error);
  }
};
