import { useEffect } from 'react';

export function useOutsideClick(ref, onClickOut) {
  useEffect(() => {
    const onClick = (e) => {
      if (ref?.current && !ref.current.contains(e.target)) {
        onClickOut?.();
      }
    };
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  }, [ref]);
}
