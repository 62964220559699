import { Input, Button, Alert } from '@material-tailwind/react';
import { Link, useNavigate } from 'react-router-dom';
import { atom, useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { atomWithToggle } from '../components/AtomWithToggle';
import ErrorIcon from '../components/ErrorIcon';
import { API_URL } from '../helpers/constants';

let errorMessageAtom = atom();
const hasErrorsAtom = atomWithToggle(false);
const emailAtom = atom();

async function resetPassword(email) {
  return fetch(API_URL + '/send_reset_request?email=' + email.email, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((response) => {
    if (!response.ok) {
      return response
        .json()
        .catch(() => {
          throw new Error(response.status);
        })
        .then(({ message }) => {
          throw new Error(message);
        });
    }
    return response.json();
  });
}

function ResetPassword() {
  const { t } = useTranslation(['common', 'buttons']);
  const navigate = useNavigate();
  const [email, setEmail] = useAtom(emailAtom);
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom);
  const [hasErrors, toggleError] = useAtom(hasErrorsAtom, false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response;
    try {
      response = await resetPassword({
        email
      });
      if (response == null) {
        toggleError(false);
        navigate('/', { replace: true });
      } else {
        setErrorMessage('Something went wrong');
        toggleError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="h-screen">
        <div className="container h-full px-6 py-24">
          <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
            <div className="flex justify-center mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
              <img src="/logo2.png" className="w-[80%]" alt="Logo" />
            </div>
            <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
              <div className="flex w-full flex-col mb-4">
                {hasErrors ? (
                  <Alert color="red" icon={<ErrorIcon />}>
                    {errorMessage}
                  </Alert>
                ) : null}
              </div>
              <form onSubmit={handleSubmit}>
                <div className="relative mb-6">
                  <Input
                    id="email"
                    color="blue"
                    label={t('common:enterEmail')}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="flex justify-between relative mb-6 space-x-1">
                  <Link to="/">
                    <Button color="blue">{t('buttons:goBack')}</Button>
                  </Link>
                  <Button color="green" type="submit">
                    {t('buttons:resetPassword')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
