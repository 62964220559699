import { API_URL } from '../helpers/constants';
import { getUser } from '../utils/session';
import { normalizeCompanyData } from '../utils/helpers';

export const fetchCompanies = (setCompaniesData) => {
  try {
    const user = getUser();
    fetch(API_URL + '/list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        data = normalizeCompanyData(data);
        setCompaniesData(data);
      });
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export async function deleteCompany(company_id) {
  try {
    const user = getUser();
    const response = await fetch(`${API_URL}/companies/${company_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });
    if (response.ok) {
      const json = await response.json();

      return json;
    }
  } catch (e) {
    console.log('Error', e);
  }
}
