import { useEffect, useState } from 'react';
import { Input, Button } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useAtom } from 'jotai';

import FormError from '../../components/FormError/FormError';
import PhoneInput from '../../components/PhoneInput/PhoneInput';
import Notification from '../../components/Notification';
import PinModal from '../../components/modals/PinModal/PinModal';
import { isPhoneValid } from '../../utils/validation';
import { registerUser, registerUserInExistingCompany } from '../../api/auth';
import { notificationAtom, modalAtom, isLoadingAtom } from '../../atoms/app';
import { countries } from '../../helpers/constants';
import { isValidEmail } from '../../utils/validation';

const SignUp = () => {
  const { t } = useTranslation(['common', 'buttons']);
  const navigate = useNavigate();
  const [modal, setModal] = useAtom(modalAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const defaultValues = {
    phone: '+1'
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  const onSubmit = async (data, e) => {
    try {
      setNotification({ isOpen: false, type: '', message: '' });
      setIsLoading(true);
      setIsSuccess(false);
      const res = await registerUser(data);
      setIsSuccess(true);
      if (res?.pincode_sent) {
        setNotification({ isOpen: false, type: '', message: '' });
        setIsLoading(false);
        return setModal({
          name: 'pin',
          isOpen: true,
          data: { email: res.sent_to, formData: data }
        });
      }
      setIsLoading(false);
      navigate('/', { replace: true });
    } catch (e) {
      console.log('submit error', e);
      setIsLoading(false);
      setNotification({
        isOpen: true,
        type: 'failure',
        message: e.message || t('common:somethingWrong')
      });
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    if (isSubmitSuccessful && isSuccess) {
      reset();
    }
  }, [isSubmitSuccessful, isSuccess, reset]);

  useEffect(() => {
    return () => {
      setNotification({ isOpen: false, type: '', message: '' });
    };
  }, []);

  const watchFormPassword = watch('password', '');

  return (
    <section className="h-screen">
      <form noValidate>
        <Notification isTempopary={false} />
        <PinModal size={'md'} />
        <div className="container h-full px-6 py-24">
          <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
            <div className="flex justify-center mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
              <img src="/logo2.png" className="w-[80%]" alt="Logo" />
            </div>
            <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  label={t('common:userName')}
                  {...register('username', { required: true })}
                />
                <FormError text={t('common:fieldRequired')} isVisible={errors?.username} />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  label={t('common:email')}
                  {...register('email', {
                    required: { value: true, message: t('common:fieldRequired') },
                    validate: { email: (value) => isValidEmail(value) || t('common:emailInvalid') }
                  })}
                />
                <FormError text={errors?.email?.message} isVisible={errors?.email} />
              </div>
              <div className="relative mb-6">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      ref={null}
                      countries={countries}
                      onChange={field.onChange}
                    />
                  )}
                  rules={{
                    required: { value: true, message: t('common:fieldRequired') },
                    validate: {
                      isValid: (value) => isPhoneValid(value) || t('common:phoneNotValid')
                    }
                  }}
                />
                <FormError text={errors?.phone?.message} isVisible={errors?.phone} />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  label={t('common:firstName')}
                  {...register('fname', { required: true })}
                />
                <FormError text={t('common:fieldRequired')} isVisible={errors?.firstName} />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  label={t('common:lastName')}
                  {...register('lname', { required: true })}
                />
                <FormError text={t('common:fieldRequired')} isVisible={errors?.lastName} />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  icon={
                    showPassword ? (
                      <FiEyeOff className="cursor-pointer" onClick={toggleShowPassword} />
                    ) : (
                      <FiEye className="cursor-pointer" onClick={toggleShowPassword} />
                    )
                  }
                  type={showPassword ? 'text' : 'password'}
                  label={t('common:password')}
                  {...register('password', {
                    required: { value: true, message: t('common:fieldRequired') },
                    minLength: {
                      value: 8,
                      message: t('common:passwordLength')
                    }
                  })}
                />
                <FormError text={errors?.password?.message} isVisible={errors?.password} />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  icon={
                    showPassword ? (
                      <FiEyeOff className="cursor-pointer" onClick={toggleShowPassword} />
                    ) : (
                      <FiEye className="cursor-pointer" onClick={toggleShowPassword} />
                    )
                  }
                  type={showPassword ? 'text' : 'password'}
                  label={t('common:repeatPassword')}
                  {...register('password_repeat', {
                    validate: (value) =>
                      value === watchFormPassword || t('common:passwordMissmatch')
                  })}
                />
                <FormError
                  text={errors?.password_repeat?.message}
                  isVisible={errors?.password_repeat}
                />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  label={t('common:companyName')}
                  {...register('company_name', { required: true })}
                />
                <FormError text={t('common:fieldRequired')} isVisible={errors?.company_name} />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  label={t('common:department')}
                  {...register('dept', { required: true })}
                />
                <FormError text={t('common:fieldRequired')} isVisible={errors?.dept} />
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <span className="mr-1 text-sm text-gray-600">{t('common:haveAccount')}</span>
                  <Link to="/" className="underline text-blue-600 hover:text-blue-400">
                    {t('buttons:signIn')}
                  </Link>
                </div>
                <Button onClick={handleSubmit(onSubmit)} color="green" type="submit">
                  {t('buttons:signUp')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default SignUp;
