import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Loader from './components/Loader';

import './helpers/i18n';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    {/* <Suspense fallback={<Loader />}> */}
    <Suspense fallback={''}>
      <App />
    </Suspense>
  </BrowserRouter>
);
