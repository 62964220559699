import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loadableEmailsAtom } from '../../atoms/company';
import Loader from '../../components/Loader';
import useColumns from './useColumns';

const EmailsGrid = ({}) => {
  const { t } = useTranslation(['common']);
  const [emails] = useAtom(loadableEmailsAtom);
  const columns = useColumns(t);

  const gridStyle = {
    minHeight: '35vh'
  };

  return (
    <div className="min-h-[35vh]">
      {emails.state === 'loading' && <Loader height={0} />}
      {emails.state === 'hasData' && (
        <ReactDataGrid
          idProperty="emailsGrid"
          columns={columns}
          rowHeight={null}
          rowMinHeight={60}
          sortable={false}
          style={gridStyle}
          dataSource={emails?.data ? emails?.data : []}
        />
      )}
    </div>
  );
};

export default EmailsGrid;
