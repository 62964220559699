import { atom } from 'jotai';
import { loadable } from 'jotai/utils';

import { currentCompanyAtom } from './companies';
import { getInfo, getDevices, getEmails } from '../api/company';

export const loadInfoAtom = atom(async (get) => {
  const currentCompany = get(currentCompanyAtom);

  if (!currentCompany) {
    return {};
  }

  const info = await getInfo(currentCompany);

  return info;
});

export const loadableInfoAtom = loadable(loadInfoAtom);

export const loadDevicesAtom = atom(async (get) => {
  const currentCompany = get(currentCompanyAtom);

  if (!currentCompany) {
    return {};
  }

  const devices = await getDevices(currentCompany);

  return devices;
});

export const loadableDevicesAtom = loadable(loadDevicesAtom);

export const loadEmailsAtom = atom(async (get) => {
  const currentCompany = get(currentCompanyAtom);

  if (!currentCompany) {
    return {};
  }

  const emails = await getEmails(currentCompany);

  return emails;
});

export const loadableEmailsAtom = loadable(loadEmailsAtom);
