import Navbar from './Navbar';

const Header = () => (
  <>
    <header>
      <Navbar />
    </header>
  </>
);

export default Header;
