import { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { Dialog, DialogHeader } from '@material-tailwind/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  generateSelectOptions,
  setSelectValue,
  formDefaultCompanies
} from '../../../utils/helpers';
import { getUserAccess } from '../../../api/users';
import { modalAtom, isLoadingAtom } from '../../../atoms/app';
import { companiesDataAtom } from '../../../atoms/companies';
import AccessForm from './AccessForm';
import Loader from '../../../components/Loader';
import CloseButton from '../../../components/CloseButton/CloseButton';

const AccessModal = ({ size }) => {
  const [userAccess, setUserStateAccess] = useState({});
  const [companiesData] = useAtom(companiesDataAtom);
  const [modal, setModal] = useAtom(modalAtom);
  const { t } = useTranslation(['common', 'buttons']);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const { name, isOpen, data } = modal;
  const { parent_company, access = {} } = userAccess;
  const options = useMemo(() => generateSelectOptions(companiesData), [companiesData]);
  const defaultValues = useMemo(() => {
    return {
      parentCompany: setSelectValue(parent_company?.id, options),
      ...formDefaultCompanies(access)
    };
  }, [parent_company, options, access]);

  const handleClose = () => {
    setModal({ name: '', isOpen: false, data: {} });
    setUserStateAccess({});
  };

  const loadUserAccess = async (userId) => {
    try {
      setIsLoading(true);
      const userAccess = await getUserAccess(userId);
      setIsLoading(false);
      setUserStateAccess(userAccess);
    } catch (e) {
      console.log('Error', e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.userId && name === 'access') {
      loadUserAccess(data?.userId);
    }
  }, [data?.userId]);

  return (
    <>
      <Dialog open={isOpen && name === 'access'} size={size || 'sm'} handler={handleClose}>
        <DialogHeader className="justify-between">
          {t('common:manageAccess')}
          <CloseButton handleClose={handleClose} />
        </DialogHeader>
        {isLoading || !parent_company?.id ? (
          <Loader height="600px" />
        ) : (
          <AccessForm
            defaultValues={defaultValues}
            setUserStateAccess={setUserStateAccess}
            userAccess={userAccess}
            loadUserAccess={loadUserAccess}
            userId={data?.userId}
          />
        )}
      </Dialog>
    </>
  );
};

export default AccessModal;
