export function capFirstLetterInEveryWord(string) {
  if (!string) {
    return '';
  }
  let parts = string.split(' ');
  for (let i = 0; i < parts.length; i++) {
    parts[i] = capitalizeFirstLetter(parts[i]);
    // parts[i].charAt(0).toUpperCase() + parts[i].slice(1).toLowerCase();
  }
  return parts.join(' ');
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
