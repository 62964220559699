import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function DraggableTimeIndicator({ xScale, timeIndicator, height, callback }) {
  const groupRef = useRef();

  useEffect(() => {
    // Select the group containing the line and circle
    const group = d3.select(groupRef.current);

    // Apply drag behavior to the group (affecting both line and circle)
    group.call(
      d3.drag().on('drag', function (event) {
        const newX = Math.min(
          xScale.range()[1], // Maximum draggable x based on your scale
          Math.max(xScale.range()[0], event.x) // Minimum draggable x
        );

        // Move the line and circle
        d3.select(this).select('line').attr('x1', newX).attr('x2', newX);
        d3.select(this).select('circle').attr('cx', newX);

        callback(xScale.invert(newX));
      })
    );
  }, [xScale, callback]);

  return (
    <g ref={groupRef}>
      <line
        x1={xScale(timeIndicator)}
        y1={height}
        x2={xScale(timeIndicator)}
        y2="0"
        stroke="red"
        strokeWidth="2"
        className="grabbable"
      />
      <circle
        cx={xScale(timeIndicator)}
        cy={height - 5.6}
        r="5"
        fill="white"
        stroke="red"
        strokeWidth="1"
        className="grabbable"
      />
    </g>
  );
}

export default DraggableTimeIndicator;
