import cls from 'classnames';

const StatusCircle = ({ status, customStyle = '' }) => {
  return (
    <div
      className={cls(`rounded-full h-3.5 w-3.5 ${customStyle}`, {
        'bg-green-500': status === 0,
        'bg-yellow-500': status === 1,
        'bg-orange-500': status === 2,
        'bg-red-500': status === 3
      })}
    ></div>
  );
};

export default StatusCircle;
