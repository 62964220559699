import { API_URL } from '../helpers/constants';
import { capitalizeFirstLetter } from '../utils/strings';
import { getUser } from '../utils/session';

export async function getUsers(selectedCompany) {
  const userUrl = `${API_URL}/get_users/${selectedCompany}`;

  const user = getUser();

  try {
    const response = await fetch(userUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });

    if (response.ok) {
      const json = await response.json();
      const processedUsers = json
        .map((user) => {
          if (user.user_id && user.first_name && user.last_name) {
            return {
              id: user.user_id,
              firstName: capitalizeFirstLetter(user.first_name),
              lastName: capitalizeFirstLetter(user.last_name),
              ...user
            };
          }
        })
        .filter((user) => user !== null);

      return processedUsers;
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}

export async function createUser(data) {
  const user = getUser();

  try {
    const response = await fetch(API_URL + '/add_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(data)
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.log(json);
      throw new Error(json.detail);
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}

export async function deleteUser(userId) {
  const userUrl = `${API_URL}/delete/${userId}`;

  const user = getUser();

  try {
    const response = await fetch(userUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });

    if (response.ok) {
      const json = await response.json();

      return json;
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}

export async function updateUser(userId, data) {
  const userUrl = `${API_URL}/update_user/${userId}`;

  const user = getUser();

  try {
    const response = await fetch(userUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      const json = await response.json();

      return json;
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}

export async function getUserAccess(userId) {
  const userUrl = `${API_URL}/get_user_access/${userId}`;

  const user = getUser();

  try {
    const response = await fetch(userUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });

    if (response.ok) {
      const json = await response.json();

      return json;
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}
export async function getUserData() {
  const userUrl = `${API_URL}/get_user_data/`;

  const user = getUser();

  try {
    const response = await fetch(userUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });

    if (response.ok) {
      const json = await response.json();

      return json;
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}

export async function setUserAccess(userId, data) {
  const userUrl = `${API_URL}/set_user_access/${userId}`;

  const user = getUser();

  try {
    const response = await fetch(userUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      const json = await response.json();

      return json;
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}
