import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export function isValidEmail(email) {
  const expression = new RegExp(
    '^([a-zA-Z0-9_\\-\\.]+)@' + '([a-zA-Z0-9_\\-\\.]+)' + '\\.([a-zA-Z]{2,5})$'
  );
  return expression.test(email);
}

export function formEmailsArray(emails) {
  if (!emails) {
    return [];
  }
  return emails
    .split(',')
    .map((email) => email.trim())
    .filter((email) => isValidEmail(email));
}

export function isAtleastOneInvalidEmail(emails) {
  if (!emails) {
    return false;
  }
  return emails
    .split(',')
    .map((email) => email.trim())
    .some((email) => !isValidEmail(email));
}
