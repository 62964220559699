import { useAtom } from 'jotai';
import { Input, Button, Textarea } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { notificationAtom, isLoadingAtom } from '../../atoms/app';
import Header from '../../components/Header';
import FormError from '../../components/FormError/FormError';
import Notification from '../../components/Notification';
import { isValidEmail } from '../../utils/validation';
import { contactus } from '../../api/share';
const ContactUs = () => {
  const { t } = useTranslation(['buttons', 'common']);
  const [notification, setNotification] = useAtom(notificationAtom);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors, isSubmitSuccessful }
  } = useForm({});

  const onSubmit = async (data, event) => {
    try {
      await contactus(data);
      setNotification({ isOpen: true, type: 'success', message: t('common:messageSent') });
      reset();
    } catch (e) {
      console.log('submit error', e);
      setNotification({
        isOpen: true,
        type: 'failure',
        message: e.message || t('common:somethingWrong')
      });
    }
  };

  return (
    <>
      <Header />
      {<Notification isTempopary={true} />}
      <div className="container h-full px-6 py-16">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
          <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  label={t('common:firstName')}
                  {...register('first_name', { required: true })}
                />
                <FormError text={t('common:fieldRequired')} isVisible={errors?.first_name} />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  label={t('common:lastName')}
                  {...register('last_name', { required: true })}
                />
                <FormError text={t('common:fieldRequired')} isVisible={errors?.last_name} />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  type="email"
                  label="Email"
                  {...register('email', {
                    required: { value: true, message: t('common:fieldRequired') },
                    validate: { email: (value) => isValidEmail(value) || t('common:emailInvalid') }
                  })}
                />
                <FormError text={errors?.email?.message} isVisible={errors?.email} />
              </div>
              <div className="relative mb-6">
                <Input color="blue" label={t('common:companyName')} {...register('company_name')} />
                <FormError isVisible={false} />
              </div>
              <div className="relative mb-6">
                <Input
                  required
                  color="blue"
                  label={t('common:subject')}
                  {...register('subject', { required: true })}
                />
                <FormError text={t('common:fieldRequired')} isVisible={errors?.subject} />
              </div>
              <div className="mb-6">
                <Textarea
                  required
                  {...register('message', { required: true })}
                  color="blue"
                  variant="outlined"
                  label={t('common:message')}
                />
                <FormError text={t('common:fieldRequired')} isVisible={errors?.message} />
              </div>
              <div className="relative mb-6">
                <Button type="submit" color="green">
                  {t('buttons:sendMessage')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
