import { useAtom } from 'jotai';
import { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LuUser2 } from 'react-icons/lu';
import { MdLogout } from 'react-icons/md';
import { TbSettings } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { appUserAtom } from '../../atoms/app';

const UserDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(['menu']);
  const [appUser, setAppUser] = useAtom(appUserAtom);

  const { user_name: userName, user_role: userRole } = appUser || {};

  const wrapperRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick(wrapperRef, () => {
    setIsOpen(false);
  });

  const logout = () => {
    Cookies.remove('user');
    navigate('/', { replace: true });
    window.location.reload();
  };

  return (
    <div className="relative" ref={wrapperRef}>
      <LuUser2
        onClick={toggleDropdown}
        alt="avatar"
        className="inline-block object-cover object-center w-11 h-11 rounded-full cursor-pointer border-2 hover:border-gray-300 border-gray-200"
      />
      {isOpen && (
        <ul className="absolute top-12 right-0 z-10 flex w-max flex-col gap-2 overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 text-base font-normal shadow-lg shadow-blue-gray-500/10 focus:outline-none">
          <p className="font-sans px-3 antialiased font-medium  leading-normal text-inherit">
            {userName}
          </p>

          <p className="font-sans px-3 text-sm antialiased leading-normal text-inherit">
            {userRole}
          </p>
          <hr className="my-2 border-blue-gray-50" role="menuitem" />
          <Link
            to="/change-password"
            className="flex w-full text-base cursor-pointer select-none items-center gap-2 rounded-md px-3 pt-[9px] pb-2 text-start leading-tight outline-none transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
          >
            <TbSettings className="w-4 h-4" />
            <p className="antialiased text-sm font-bold  text-inherit">
              {t('menu:changePassword')}
            </p>
          </Link>

          <Link
            onClick={logout}
            className="flex w-full text-base cursor-pointer select-none items-center gap-2 rounded-md px-3 pt-[9px] pb-2 text-start leading-tight outline-none transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
          >
            <MdLogout className="w-4 h-4" />
            <div className="items-right flex-col md:flex antialiased text-sm font-bold text-inherit">
              {t('menu:logout')}
            </div>
          </Link>
        </ul>
      )}
    </div>
  );
};

export default UserDropdown;
