import { useAtom } from 'jotai';
import { Alert } from '@material-tailwind/react';
import { MdErrorOutline, MdCheckCircleOutline } from 'react-icons/md';
import cls from 'classnames';

import { notificationAtom } from '../atoms/app';

const Notification = ({ isTempopary }) => {
  const [notification, setNotification] = useAtom(notificationAtom);
  const { isOpen, type, message } = notification;
  if (isTempopary && isOpen) {
    setTimeout(() => {
      setNotification({ isOpen: false, type: '', message: '' });
    }, 3000);
  }

  const TypeIconSelector = ({ type }) => {
    switch (type) {
      case 'success':
        return <MdCheckCircleOutline />;
      case 'failure':
        return <MdErrorOutline />;
      default:
        return undefined;
    }
  };

  return (
    <Alert
      open={isOpen}
      onClose={() => setNotification({ isOpen: false, type: '', message: '' })}
      className={cls('z-20 absolute rounded-none bg-green-500 font-medium text-white', {
        'bg-red-500': type === 'failure'
      })}
    >
      <div className="flex items-center justify-between">
        <span className="mr-2">{<TypeIconSelector type={type} />}</span>
        {message}
      </div>
    </Alert>
  );
};

export default Notification;
