import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loadableDevicesAtom } from '../../atoms/company';
import Loader from '../../components/Loader';
import useColumns from './useColumns';

const DevicesGrid = ({}) => {
  const { t } = useTranslation(['common']);
  const [devices] = useAtom(loadableDevicesAtom);

  const columns = useColumns(t);
  const gridStyle = {
    minHeight: '35vh'
  };
  return (
    <>
      {devices.state === 'loading' && <Loader height={0} />}
      {devices.state === 'hasData' && (
        <ReactDataGrid
          idProperty="devicesGrid"
          columns={columns}
          rowHeight={60}
          rowMinHeight={60}
          sortable={false}
          style={gridStyle}
          dataSource={devices?.data ? devices?.data : []}
        />
      )}
    </>
  );
};

export default DevicesGrid;
