import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Typography, MenuItem } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';

import { navListMenuItems } from './constatns';

const MenuItems = ({}) => {
  const { t } = useTranslation(['menu']);
  return (
    <>
      {navListMenuItems.map(({ icon, title, description, url }, key) => (
        <Link to={url} key={key}>
          <MenuItem className="flex items-center gap-3 rounded-lg">
            {React.createElement(icon, {
              className: 'h-4 text-gray-900 w-4'
            })}
            <div>
              <Typography
                variant="h6"
                color="blue-gray"
                className="flex items-center text-sm font-bold"
              >
                {t(`menu:${title}`)}
              </Typography>
              <Typography variant="paragraph" className="text-xs !font-medium text-blue-gray-500">
                {description}
              </Typography>
            </div>
          </MenuItem>
        </Link>
      ))}
    </>
  );
};

export default MenuItems;
