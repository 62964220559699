import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

import langs from '../../helpers/langs';
import { cutLanguageCode, setSelectValue } from '../../utils/helpers';
import DropdownIndicator from './DropdownIndicator';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const handleLangChange = (data) => {
    if (language !== data.value) {
      i18n.changeLanguage(data.value);
    }
  };

  const langWithoutCode = cutLanguageCode(i18n.language);
  return (
    <ReactSelect
      components={{ DropdownIndicator }}
      options={langs}
      value={setSelectValue(langWithoutCode, langs)}
      onChange={handleLangChange}
      isSearchable={false}
      className="w-[77px] mr-4"
      classNames={{
        control: () => '[&&]:rounded-xl [&&]:cursor-pointer font-medium',
        dropdownIndicator: () => '[&&]:text-black hover:[&&]:text-black',
        option: () => '[&&]:cursor-pointer'
      }}
    ></ReactSelect>
  );
};

export default LanguageSelector;
