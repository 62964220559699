import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import CompanySelector from './CompanySelector';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import { companiesDataAtom, currentCompanyAtom } from '../atoms/companies';
import { fetchCompanies } from '../api/companies';
import UserDropdown from './UserDropdown/UserDropdown';
import NavListMenu from './NavListMenu/NavListMenu';

function Navbar() {
  const { t } = useTranslation(['menu']);
  const [companiesData, setCompaniesData] = useAtom(companiesDataAtom);
  const [selectedCompany] = useAtom(currentCompanyAtom);

  const companyVideoUrl = '/videos/' + selectedCompany;

  useEffect(() => {
    fetchCompanies(setCompaniesData);
  }, []);

  return (
    <>
      <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
        <div id="header" className="flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to={companyVideoUrl} className="flex h-20 items-center">
            <img src="/logo2.png" className="h-12 mx-4" alt="Verify AI Logo" />
          </Link>
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto "
            id="navbar-sticky"
          >
            <ul className="flex items-center flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  to={`/company/${selectedCompany}`}
                  className="block py-2 px-3  bg-blue-700 rounded md:bg-transparent text-blue-700 hover:text-blue-500"
                  aria-current="page"
                >
                  {t('menu:company')}
                </Link>
              </li>
              <li>
                <Link
                  to={'/user-list'}
                  className="block py-2 px-3  bg-blue-700 rounded md:bg-transparent text-blue-700 hover:text-blue-500"
                  aria-current="page"
                >
                  {t('menu:users')}
                </Link>
              </li>
              <li>
                <Link
                  to={companyVideoUrl}
                  className="block py-2 px-3 bg-blue-700 rounded md:bg-transparent text-blue-700 hover:text-blue-500"
                  aria-current="page"
                >
                  {t('menu:videos')}
                </Link>
              </li>
              <li>
                <NavListMenu />
              </li>
              <li>
                <Link
                  to={'/video-upload'}
                  className="block py-2 px-3 bg-blue-700 rounded md:bg-transparent text-blue-700 hover:text-blue-500"
                  aria-current="page"
                >
                  {t('menu:uploadVideo')}
                </Link>
              </li>
            </ul>
          </div>

          <div>{companiesData && <CompanySelector />}</div>

          <div className="items-center flex ">
            <LanguageSelector />
            <UserDropdown />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
