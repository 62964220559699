import { useMemo } from 'react';
import { MdOutlineEdit, MdDeleteOutline } from 'react-icons/md';

const useColumns = (t, userRole, handleOpen) => {
  const columns = [
    {
      name: 'index',
      header: '',
      defaultWidth: 50,
      render: ({ remoteRowIndex }) => remoteRowIndex + 1
    },
    {
      name: 'firstName',
      defaultFlex: 1,
      header: t('firstName')
    },
    {
      name: 'lastName',
      defaultFlex: 1,
      header: t('lastName')
    },
    {
      name: 'email',
      defaultFlex: 1,
      header: t('email')
    },
    {
      name: 'department',
      defaultFlex: 1,
      header: t('department')
    },
    {
      name: 'user_type',
      defaultFlex: 1,
      header: t('userType'),
      render: ({ value }) => t(`${value}`)
    },
    {
      name: 'parent_company',
      defaultFlex: 1,
      header: t('parentCompany')
    }
  ];

  if (userRole === 'system-admin') {
    columns.push({
      name: 'controls',
      header: '',
      defaultWidth: 120,
      render: ({ data }) => {
        return (
          <>
            {data.user_type === 'ui_user' && (
              <div className="flex justify-evenly">
                <div
                  onClick={() => handleOpen('access', data.user_id)}
                  className="rounded-lg cursor-pointer hover:bg-gray-300 group/edit invisible group-hover/item:visible py-2 px-4"
                >
                  <MdOutlineEdit className="h-4 w-4" />
                </div>

                <div
                  onClick={() => handleOpen('remove', data.user_id)}
                  className="rounded-lg cursor-pointer  hover:bg-gray-300 group/edit invisible group-hover/item:visible py-2 px-4"
                >
                  <MdDeleteOutline className="h-4 w-4" />
                </div>
              </div>
            )}
          </>
        );
      }
    });
  }

  const formedColumns = useMemo(() => columns, [userRole, handleOpen, t]);

  return formedColumns;
};

export default useColumns;
