import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';
import { Button } from '@material-tailwind/react';

import { modalAtom, isLoadingAtom, notificationAtom, appUserAtom } from '../atoms/app';
import { loadableUsersAtom } from '../atoms/users';
import { deleteUser } from '../api/users';
import BackButton from '../components/BackButton/BackButton';
import UsersGrid from '../components/UsersGrid/UsersGrid';
import Notification from '../components/Notification';
import RemoveModal from '../components/modals/RemoveModal/RemoveModal';
import EditUserModal from '../components/modals/EditUserModal/EditUserModal';
import AccessModal from '../components/modals/AccessModal/AccessModal';
import CurrentUser from '../components/CurrentUser/CurrentUser';
import { refrestUsersAtom } from '../atoms/users';
import Header from '../components/Header';
import { isUserAdmin } from '../utils/session';

function UserList() {
  const { t } = useTranslation(['common', 'buttons']);

  const [appUser, setAppUser] = useAtom(appUserAtom);
  const [users] = useAtom(loadableUsersAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [refresh, setRefresh] = useAtom(refrestUsersAtom);

  const [modal, setModal] = useAtom(modalAtom);
  const { name, isOpen, data } = modal || {};
  const isAdmin = isUserAdmin();
  const navigate = useNavigate();
  const { user_id } = appUser || {};
  const { username, firstName, lastName, email, parent_company } =
    (users.state === 'hasData' && users?.data?.find((c) => c.user_id === user_id)) || {};
  const defaultValues = {
    username,
    firstName,
    lastName,
    email
  };
  const handleClose = () => {
    if (!isLoading) {
      setModal({ name: '', isOpen: false, data: {} });
    }
  };

  const handleRemove = async (e, userId) => {
    try {
      setIsLoading(true);
      await deleteUser(userId);
      handleClose();
      setIsLoading(false);
      setNotification({ isOpen: true, type: 'success', message: t('common:userDeleted') });
      setRefresh((prev) => prev + 1);
    } catch (e) {
      console.log('Error', e);
      setNotification({ isOpen: true, type: 'failure', message: t('common:somethingWrong') });
    }
  };

  return (
    <>
      <Header></Header>
      {<Notification isTempopary={true} />}
      <BackButton customStyle="ml-4" />
      <div className={'w-full px-4 font-semibold'}>
        <CurrentUser />
        <div className="flex justify-between items-center">
          <h3 className="text-blue-main font-semibold my-6">{t('common:allUsers')}:</h3>
          {isAdmin && (
            <Button
              ripple={false}
              onClick={() => navigate('/create-user')}
              className="flex items-center"
              variant="gradient"
              color="light-blue"
            >
              <FaPlus className="mr-1 text-sm" />
              <span>{t('buttons:addUser')}</span>
            </Button>
          )}
        </div>
        <UsersGrid />
      </div>

      <RemoveModal
        bodyText={'actionIrreversibleUser'}
        handleRemove={handleRemove}
        handleClose={handleClose}
      />

      {defaultValues.email && <EditUserModal defaultValues={defaultValues} size="md" />}
      <AccessModal size="md" />
    </>
  );
}

export default UserList;
