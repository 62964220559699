import { useMemo } from 'react';

const useColumns = (t) => {
  const columns = [
    {
      name: 'index',
      header: '',
      defaultWidth: 50,
      render: ({ remoteRowIndex }) => remoteRowIndex + 1
    },
    {
      name: 'ALIAS',
      header: t('alias')
    },
    {
      name: 'TZ',
      header: t('tz'),
      textAlign: 'center',
      render: ({ value }) => <div className="text-center">{value > 0 ? `+${value}` : value}</div>
    },
    {
      name: 'HARDWARE_ID',
      header: t('hwId')
    },
    {
      name: 'MODE',
      header: t('mode'),
      render: ({ value }) => (value === 0 ? t('automatic') : t('manual'))
    },
    {
      name: 'DEPARTMENT',
      header: t('department')
    },
    {
      name: 'PHONE',
      header: t('phone')
    },
    {
      name: 'EMAIL',
      header: t('email')
    }
  ];

  const formedColumns = useMemo(() => columns, [t]);

  return formedColumns;
};

export default useColumns;
