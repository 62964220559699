import { Dialog, DialogHeader } from '@material-tailwind/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { modalAtom, isLoadingAtom } from '../../../atoms/app';
import SubscriptionForm from './SubscriptionForm';
import Loader from '../../Loader';
import CloseButton from '../../CloseButton/CloseButton';

const SubscriptionModal = ({ size }) => {
  const { t } = useTranslation(['common', 'buttons']);
  const [modal, setModal] = useAtom(modalAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const { name, isOpen, data } = modal;

  const handleClose = () => {
    setModal({ name: '', isOpen: false, data: {} });
  };

  return (
    <>
      <Dialog open={isOpen && name === 'subscription'} size={size || 'sm'} handler={handleClose}>
        <DialogHeader className="justify-between">
          {t('common:addSubscription')}
          <CloseButton handleClose={handleClose} />
        </DialogHeader>
        {isLoading ? <Loader height="400px" /> : <SubscriptionForm />}
      </Dialog>
    </>
  );
};

export default SubscriptionModal;
