import { FlagImage } from 'react-international-phone';

const CustomOption = ({ value, label, code }) => {
  return (
    <div className="flex items-center">
      <FlagImage iso2={value} className="h-5 w-5 mr-2" />
      <span className="mr-2">{label}</span>
      <span className="text-gray-500">+{code}</span>
    </div>
  );
};

export default CustomOption;
