import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header';
import DocNavigation from './DocNavigation';
const Documentation = () => {
  const { t } = useTranslation(['menu']);
  return (
    <>
      <Header />
      <div className="h-full px-6 py-3">
        <div className="flex">
          <DocNavigation />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Documentation;
