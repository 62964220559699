import cls from 'classnames';

const SpeedButton = ({ children, customStyle = '', onClick }) => {
  return (
    <button
      className={cls(
        `flex justify-center 2xl:w-24 lg:w-18 w-14 mr-2 p-2 border border-blue-400 rounded-lg text-lg  ${customStyle}`
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SpeedButton;
