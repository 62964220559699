export default [
  {
    value: 'en',
    label: 'EN'
  },
  {
    value: 'fr',
    label: 'FR'
  },
  {
    value: 'de',
    label: 'DE'
  }
];
