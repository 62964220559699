import { useTranslation } from 'react-i18next';
import { Typography } from '@material-tailwind/react';
import {
  TbSpace,
  TbSquareArrowLeft,
  TbSquareArrowRight,
  TbSquareLetterW,
  TbSquareLetterA,
  TbSquareArrowUp,
  TbSquareArrowDown
} from 'react-icons/tb';

const ShortcutsInfo = () => {
  const { t } = useTranslation(['common', 'buttons']);

  return (
    <div>
      <Typography className="mb-3" variant="h5" color="blue-gray">
        {t('common:keyboardShortcuts')}:
      </Typography>
      <div className="flex items-center text-base mb-1">
        {t('buttons:playStop')} - {t('buttons:space')} <TbSpace className="ml-1 h-6 w-6" />
      </div>
      <div className="flex items-center text-base mb-1">
        {t('buttons:frameForward')} -
        <TbSquareArrowRight className="ml-1 h-6 w-6" />
      </div>
      <div className="flex items-center text-base mb-1">
        {t('buttons:frameBackward')} -
        <TbSquareArrowLeft className="ml-1 h-6 w-6" />
      </div>
      <div className="flex items-center text-base mb-1">
        {t('buttons:5sForward')} - {t('buttons:doubleArrowRight')}
        <TbSquareArrowRight className="ml-1 h-6 w-6" />
        <TbSquareArrowRight className="ml-1 h-6 w-6" />
      </div>
      <div className="flex items-center text-base mb-1">
        {t('buttons:5sForbackward')} - {t('buttons:doubleArrowLeft')}
        <TbSquareArrowLeft className="ml-1 h-6 w-6" />
        <TbSquareArrowLeft className="ml-1 h-6 w-6" />
      </div>
      <div className="flex items-center text-base mb-1">
        {t('buttons:increaseSpeed')} -
        <TbSquareArrowUp className="ml-1 h-6 w-6" />
      </div>
      <div className="flex items-center text-base mb-1">
        {t('buttons:decreaseSpeed')} -
        <TbSquareArrowDown className="ml-1 h-6 w-6" />
      </div>
      <div className="flex items-center text-base mb-1">
        {t('buttons:nextDetection')} -
        <TbSquareLetterW className="ml-1 h-6 w-6" />
      </div>
      <div className="flex items-center text-base mb-1">
        {t('buttons:previousDetection')} -
        <TbSquareLetterA className="ml-1 h-6 w-6" />
      </div>
    </div>
  );
};
export default ShortcutsInfo;
